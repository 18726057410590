import React, { useState, useEffect, useMemo } from "react";
import { Navigation } from "../../components/Navigation";
import "./style.css";
import { Button } from "../../components/Button";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { RingScore } from "../../components/Ring";
//---------------------------------authentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------authentication code----------------------------------------

export const SuggestedEdits_hostory = () => {
    //---------------------------------authentication code----------------------------------------
    //auth0 component
    const { isAuthenticated } = useAuth0();
    //---------------------------------authentication code----------------------------------------
    if (!isAuthenticated) {
        return <div>Please log in to view your suggested edits.</div>;
    }

    // For real data
    const location = useLocation();
    const data_received = location.state?.data;
    const index_history = location.state?.index;

    // Use useMemo to parse data_received only once when it changes
    const data = useMemo(() => JSON.parse(data_received.comments[index_history]), [data_received, index_history]);

    const work_experience = data.original.work_experience;
    const proj_experience = data.original.project_experience;
    const revise_comments = data.revised_response;
    const combinedExperience = [
        ...work_experience.map((exp, index) => ({ ...exp, type: 'Work', id: `${index}`, score: `${revise_comments[index].relevancy_score}` })),
        ...proj_experience.map((exp, index) => ({ ...exp, type: 'Project', id: `${work_experience.length + index}`, score: `${revise_comments[work_experience.length + index].relevancy_score}` }))
    ];
    const company_analysis = data.company_analysis;
    const writting_mode = data.mode;
    const job_description = location.state?.job_description;
    const original_personal_data = {
        personal_info: data.original.basic_info,
        education: data.original.education,
        skills: data.original.skills,
        certificate: data.original.certificate
    };
    const [selections, setSelections] = useState({});
    const [loading, setLoading] = useState(false); // state for loading
    const [apiResponse, setApiResponse] = useState(null);
    const navigate = useNavigate();
    // State to hold the selected experience
    const [selectedExperience, setSelectedExperience] = useState(combinedExperience[0]);

    // Set checkboxes to be checked initially, except those in aspect6
    useEffect(() => {
        const initialSelections = {};
        work_experience.forEach((_, experienceIndex) => {
            Object.keys(revise_comments[experienceIndex]).forEach(aspectKey => {
                if (aspectKey === 'aspect6') {
                    // Initialize each item in aspect6 array as unchecked
                    revise_comments[experienceIndex][aspectKey].forEach((_, idx) => {
                        initialSelections[`experience-${experienceIndex}-${aspectKey}-${idx}`] = false;
                    });
                } else {
                    initialSelections[`experience-${experienceIndex}-${aspectKey}`] = true;
                }
            });
        });
        setSelections(prevSelections => ({ ...prevSelections, ...initialSelections }));
    }, [work_experience, revise_comments]);

    useEffect(() => {
        const initialSelections_project = {};
        proj_experience.forEach((_, experienceIndex) => {
            Object.keys(revise_comments[work_experience.length + experienceIndex]).forEach(aspectKey => {
                if (aspectKey === 'aspect6') {
                    // Initialize each item in aspect6 array as unchecked
                    revise_comments[work_experience.length + experienceIndex][aspectKey].forEach((_, idx) => {
                        initialSelections_project[`experience-${work_experience.length + experienceIndex}-${aspectKey}-${idx}`] = false;
                    });
                } else {
                    initialSelections_project[`experience-${work_experience.length + experienceIndex}-${aspectKey}`] = true;
                }
            });
        });
        setSelections(prevSelections => ({ ...prevSelections, ...initialSelections_project }));
    }, [proj_experience, revise_comments]);

    // Function to handle checkbox changes, including those in aspect6
    const handleCheckboxChange = (experienceIndex, aspectKey, idx = null) => {
        const key = idx === null
            ? `experience-${experienceIndex}-${aspectKey}`
            : `experience-${experienceIndex}-${aspectKey}-${idx}`;
        setSelections(prevSelections => ({
            ...prevSelections,
            [key]: !prevSelections[key]
        }));
    };

    const getAspectTitle = (aspectKey) => {
        const aspectTitles = {
            'aspect1': 'Metric',
            'aspect2': 'Technical Skills',
            'aspect3': 'Verbs',
            'aspect4': 'Impact',
            'aspect5': 'Soft Skills',
            // Add other aspects if necessary
        };
        return aspectTitles[aspectKey] || aspectKey; // Fallback to aspectKey if no title is found
    };

    const renderAspectContent = (aspectKey, experienceIndex) => {
        const comments = revise_comments[experienceIndex][aspectKey];
        switch (aspectKey) {
            case 'aspect1':
                return `We suggest to add a metric to the experience. Our revision: ${comments}`;
            case 'aspect2':
                return `This experience matches these skills: ${comments.matched}. However, it is missing these keywords mentioned in the job description: ${comments.not_matched}. We will try our best to match these missing keywords.`;
            case 'aspect4':
                return `We suggest to add an achievement to the experience. Our revision: ${comments}`;
            // Add cases for other aspects as necessary
            default:
                return comments; // Default case for simple string comments or other aspect types
        }
    };

    const logCurrentSelections = () => {
        console.log('Current selections:', JSON.stringify(selections));
    };

    // Handler for clicking an experience button
    const handleExperienceClick = (experience) => {
        setSelectedExperience(experience);
    };

    const handleClick = (data, index) => {
        navigate('/new_resume_result_history', { state: { data, index } });
    };

    return (
        <div className="suggested-page">
            <Helmet>
                <title>Comments - ResuMaster</title>
                <meta property="og:title" content="Comments - ResuMaster" />
                <body className="result_page" />
                <script src="//embed.typeform.com/next/embed.js"></script>
            </Helmet>
            {
                isAuthenticated ? (
                    <Navigation_loggedin className="navigation-2202" />
                ) : (
                    <Navigation className="navigation-2202" />
                )
            }
            <div className="scanning_title">
                Analysis
            </div>

            <div className="common-block-container">
                <div className="company-analysis-heading">
                    <img src="/img/company_analysis_icon.png" alt="Icon" style={{ marginRight: '8px' }} />
                    <span>Company Analysis</span>
                </div>

                {company_analysis !== "null" ? (
                    <p className="company-analysis-content">{company_analysis}</p>
                ) : (
                    <p className="company-analysis-content">No analysis available.</p>
                )}
            </div>

            <div className="common-block-container">
                <div className="company-analysis-heading">
                    <img src="/img/resume_analysis_icon.png" alt="Icon" style={{ marginRight: '8px' }} />
                    <span>Resume Analysis</span>
                </div>

                <div className="navigation-bar">
                    {combinedExperience.map((experience, index) => (
                        <button
                            key={experience.id} // use unique ID
                            onClick={() => handleExperienceClick(experience)}
                            className={selectedExperience && selectedExperience.id === experience.id ? 'active' : ''}
                        >
                            Experience {index + 1}
                        </button>
                    ))}
                </div>
                {selectedExperience && (
                    <div className="experience-details">
                        <div className="score-container">
                            <RingScore score={selectedExperience.score} />
                            <div className="text-container">
                                <div className="matching-score-text">Matching Score</div>
                                {
                                    selectedExperience.score > 50 ?
                                        <div className="additional-text">
                                            You're getting there! Your resume matches quite well. A few more tweaks will help you stand out even more.
                                        </div> :
                                        <div className="additional-text">
                                            Making progress! Your resume has some relevant points but needs more targeted adjustments to catch the employer's eye. Please see below for our suggestions!
                                        </div>
                                }
                            </div>
                        </div>


                        <div className="current-experience-container">
                            {
                                selectedExperience.type === 'Work' ?
                                    <div>
                                        <div className="experience_title">
                                            {selectedExperience.job_title}
                                            {selectedExperience.company && ` at ${selectedExperience.company}`}
                                        </div>
                                        <div className="experience_duration">
                                            {selectedExperience.duration ? selectedExperience.duration : ''}
                                            {selectedExperience.location && ` ${selectedExperience.location}`}
                                        </div>
                                        {selectedExperience.job_summary.map((summary, idx) => (
                                            <li className="experience-description-list-item" key={idx}>{summary}</li>
                                        ))}
                                    </div>
                                    :
                                    <div>
                                        <div className="experience_title">
                                            {selectedExperience.project_name}
                                            {selectedExperience.institution && ` at ${selectedExperience.institution}`}
                                        </div>
                                        <div className="experience_duration">
                                            {selectedExperience.duration ? selectedExperience.duration : ''}
                                            {selectedExperience.location && ` ${selectedExperience.location}`}
                                        </div>
                                        {selectedExperience.project_description.map((summary, idx) => (
                                            <li className="experience-description-list-item" key={idx}>{summary}</li>
                                        ))}
                                    </div>
                            }
                        </div>

                        <div className="separate-text">
                            Please review and check the box next to any edits you would like to include in the revision
                        </div>

                        <div className="suggests-grid-container">
                            {Object.keys(revise_comments[selectedExperience.id]).map((aspectKey) => {
                                const aspectTitle = getAspectTitle(aspectKey);
                                const aspectContent = renderAspectContent(aspectKey, selectedExperience.id);
                                if (aspectKey === 'aspect6') {
                                    return (
                                        <div className="side-card" key={aspectKey}>
                                            <div className="side-card-text">
                                                <div className="added_description">Suggested bullet points (check to add)</div>
                                                {revise_comments[selectedExperience.id]['aspect6'].map((comment, idx) => (
                                                    <label key={idx} className="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            checked={selections[`experience-${selectedExperience.id}-aspect6-${idx}`]}
                                                            onChange={() => handleCheckboxChange(selectedExperience.id, 'aspect6', idx)}
                                                        />
                                                        <span className="checkmark">{comment}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                } else if (aspectKey === 'relevancy_score') {
                                } else {
                                    return (
                                        <div className="side-card" key={aspectKey}>
                                            <label className="side-card-checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={selections[`experience-${selectedExperience.id}-${aspectKey}`]}
                                                    onChange={() => handleCheckboxChange(selectedExperience.id, aspectKey)}
                                                />
                                                <span className="side-card-header">{aspectTitle}</span>
                                            </label>
                                            <div className="side-card-text">
                                                {aspectContent}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                )}

            </div>

            <div className="button-container">
                <Button className="suggested-edits-result-button" onClick={(e) => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(data_received, index_history);
                }} text="View Revision" />
            </div>

        </div>
    );
};

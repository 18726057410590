import React, { useCallback, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { useDropzone } from 'react-dropzone'
import { Navigation } from "../../components/Navigation";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { Button } from '../../components/Button';
import { Helmet } from 'react-helmet'
import { Loading_upload } from '../Loading';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------
import './style.css'

export const Upload = (props) => {
  //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  if (!isAuthenticated) {
    return <div>Please log in first to start revise your resume.</div>;
  }
  //---------------------------------auhentication code----------------------------------------
  const { pathname } = useLocation(); // destructuring the pathname from the useLocation hook
  const navigate = useNavigate();

  if (isAuthenticated && !user.email_verified) {
    return <div>Sorry, you need to verify your email first</div>;  // Display a message or loading animation here
  }
  
  useEffect(() => {
    window.scrollTo(0, 0); // this will scroll the page to the top every time pathname changes
  }, [pathname]);

  /*============================== temporary comment out for deployment 06/09===================================*/
  useEffect(() => {
    fetchUserBalance();
  }, []);
  /*============================== temporary comment out for deployment 06/09===================================*/

  const [uploadedFile, setUploadedFile] = useState(); // state for uploaded file
  const [jobDescription, setJobDescription] = useState(""); // state for job description
  const [targetCompany, setTargetComapny] = useState(""); // state for target company
  const [jobPosition, setJobposition] = useState("temp not available"); // state for target job position
  const [loading, setLoading] = useState(false); // state for loading
  // const history = useHistory(); // react-router history hook
  const [apiResponse, setApiResponse] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Standard");
  const [enoughBalance, setEnoughBalance] = useState(false);
  
  const fetchUserBalance = async () => {
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/check_user_status', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
    //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      console.log(data.in_subscription);
      console.log(data.current_credits);
      if (data.in_subscription === true){
        setEnoughBalance(true);
      }
      if (data.current_credits >= 100){ 
        setEnoughBalance(true);
      }
      //setLoading(false);
    }).catch(error => {
      // handle the error
      console.error("fetch user credits failed:", error);
      // If there's an error, ensure loading is turned off
      // Check if the error is due to a JSON parsing issue
      if (error.message.includes('Unexpected token')) {
        console.error('Received non-JSON response:', error);
        // You might want to set a different state here to handle this specific error
    }
      //setLoading(false);
      alert(error.message);  // Displaying the error message to the user
    });
  }

  //this useEffect code serves to enable the upload button after the user upload both resume and text
  useEffect(() => {
    // delete once recover to the pricing system
    // if ((uploadedFile && jobDescription.trim() !== '')) {
    //     setIsButtonDisabled(false);
    //   } else {
    //     setIsButtonDisabled(true);
    //   }
    // delete once recover to the pricing system
    /*============================== temporary comment out for deployment 06/09===================================*/
    if ((uploadedFile && jobDescription.trim() !== '' && jobPosition.trim() !== '') && ( enoughBalance )) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    /*============================== temporary comment out for deployment 06/09===================================*/
  }, [uploadedFile, jobDescription, jobPosition]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        try {
          //This part of code checks whether the uploaded pdf file has exactly one page, if not, it gives the error
          const binaryStr = reader.result;
          console.log(binaryStr);

          //Load the PDF document
          const doc = await PDFDocument.load(binaryStr);
          //get the number of pages
          const numPages = doc.getPageCount();
          if (numPages > 3) {
            alert('Please upload a PDF file that is either one, two, or three pages long.')
          } else {
            setUploadedFileNames([file.name]);
            setUploadedFile(file);
          }
        } catch (error) {
          alert('Unable to process the uploaded file. It may be encrypted or not a valid PDF.');
        }
      }
      reader.readAsArrayBuffer(file);
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/pdf', multiple: false })
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
      }
    //---------------------------------auhentication code----------------------------------------
    //check whether there is the upload file
    if (!uploadedFile) {
      alert('Please upload a file before proceeding.');
      return;
    }
    //check whether the user upload the job description
    if (jobDescription.trim() === '') {
      alert('Please enter a job description before proceeding.');
      return;
    }
    //check whether the user upload the job position
    if (jobPosition.trim() === '') {
        alert('Please enter a job position before proceeding.');
        return;
    }
    // create a FormData object
    const formData = new FormData();
    // append the file and the job description
    formData.append('resume', uploadedFile);
    formData.append('jobdescription', jobDescription);
    formData.append('jobPosition', jobPosition);
    formData.append('mode', selectedOption);
    if (targetCompany === "") {
        setTargetComapny("null");
        formData.append('targetcompany', targetCompany);
    } else {
        formData.append('targetcompany', targetCompany);
    }
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/revise_resume_new', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
      body: formData
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      // handle the response data
      //console.log(data);
      //update setapiresponse that is used in result.js
      setApiResponse(data);
      //stop loading and redirect to /result
      setLoading(false);
      //history.push("/result");
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);

      alert(error.message);  // Displaying the error message to the user
    });
  }

  if (loading) {
    // return (
    //     <div className="loading-screen">
    //         <BeatLoader color={"#123abc"} loading={loading} size={40} />
    //         <p>Please wait approximately 90 seconds for the suggestions</p>
    //     </div>
    // );

    return <Loading_upload />;

  } else if (apiResponse) {
    //pass the json file to the result.js file
    //console.log("Debug:", apiResponse);

    // return (
    //   <Navigate to={{
    //     pathname: "/results",
    //     state: { data: apiResponse }
    //   }}/>
    // )

    navigate('/new_resume_revision', { state: { data: apiResponse, job_description: jobDescription, jobPosition: jobPosition } });
    //pass job description to next screen
    // navigate('/new-resume-revision', { state: {job_description: jobDescription} });
  }

  return (

    <div className="upload-container">
      {/* <Navigation className="navigation-2202" /> */}
      <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
        <title>Upload - ResuMaster</title>
        <meta property="og:title" content="Upload - ResuMaster" />
      </Helmet>
      <header data-role="Header" className="upload-header">
      </header>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="upload">
        <div className="upload-main">
          <div className="title">
            <div className="title-2">Upload</div>
          </div>
          <div className="div-2 upload-box">
            <div className="title-3">Upload Your Resume <span style={{ color: '#B00000' }}>*</span></div>

            {
              uploadedFileNames.length > 0 ?
                uploadedFileNames.map((fileName, index) => (
                  <div className="file-bar" key={index}>
                    <div className="text-wrapper">{fileName}</div>
                    <img className="check" alt="Check" src="/img/check.svg" />
                  </div>
                )) : <p></p>
            }
            <div className={`upload-field ${uploadedFileNames.length > 0 ? 'selected' : ''}`} {...getRootProps()}>
              <input {...getInputProps()} />

              <img className="upload-cloud" alt="Upload cloud" src="/img/upload-cloud.png" />
              <p className="p">Drag and drop, or browse your files</p>
            </div>

            <div className="switch-container">
                <Link className="switch-text" to="/upload_resume_text">
                  OR Paste your resume text
                  </Link>
            </div>
            
          </div>

          <div className="div-2">
            <div className="title-3">Target Company</div>
            <p className="company-caption">We will try our best to align with the provided name/information , but duplicates or omissions may occur</p>
            <textarea
              value={targetCompany}
              placeholder='e.g. Google /Amazon/ Computer Software Company/SaaS B2B Company'
              onChange={e => setTargetComapny(e.target.value)}
              className="company-container"
            ></textarea>

          </div>

          {/* <div className="div-2">
            <div className="title-3">Job Position <span style={{ color: '#B00000' }}>*</span></div>
            <textarea
              value={jobPosition}
              placeholder='e.g. Software Engineer / Data Analyst / UX Designer/ Product Manager'
              onChange={e => setJobposition(e.target.value)}
              className="company-container"
            ></textarea>
          </div> */}

          <div className="div-2">
            <div className="title-3">Job Description <span style={{ color: '#B00000' }}>*</span></div>
            <textarea
              value={jobDescription}
              placeholder='Copy and paste the qualification section of the job description'
              onChange={e => setJobDescription(e.target.value)}
              className="rectangle"
            ></textarea>
          </div>

          <div className="div-2">
          <div className="title-3">Experience Length</div>
          <div className="company-caption">
            Select your experience length:
            <div><strong>Concise Mode:</strong> Delivers succinct bullet points, limit the resume to 1 page for 3-4 experiences.</div>
            <div><strong>Standard Mode:</strong> Offers a balanced mix of quality and brevity.</div>
            <div><strong>Creative Mode:</strong> Generates extended content for innovative resume writing.</div>
          </div>

            <div className='option-box'>
                <div className={`option-button ${selectedOption === "Concise" ? 'selected' : ''}`} onClick={() => setSelectedOption("Concise")}>Concise</div>
                <div className={`option-button ${selectedOption === "Standard" ? 'selected' : ''}`} onClick={() => setSelectedOption("Standard")}>Standard</div>
                <div className={`option-button ${selectedOption === "Creative" ? 'selected' : ''}`} onClick={() => setSelectedOption("Creative")}>Creative</div>
            </div>

            <div className="frame-10">
                <Link to="/GetStarted">
                    <Button className="button-secondary" text="Back" />
                </Link>

                <Button
                    className="upload-button"
                    onClick={sendToServer}
                    disabled={isButtonDisabled}
                    text="Next"
                />
            </div> 

          </div>
          {/*============================== temporary comment out for deployment 06/09===================================*/}
          { enoughBalance ? (
            <div className="sufficient-balance">
            By clicking Next, 100 ResuCredits will be deducted from your account
          </div>
          ) : (
            <div className="insufficient-balance">
              <Link to='/plan'>
                You do not have enough ResuCredits, please purchase here
              </Link>
            </div>
            
          )
          }
          {/*============================== temporary comment out for deployment 06/09===================================*/}
          </div>
      </div>
    </div>

  )
}

import React, { useRef , useState} from "react";
import { Navigation } from "../../components/Navigation";
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./style.css";
import { Button } from "../../components/Button";
import { jsPDF } from 'jspdf';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Loading_mainpage } from '../../screens/Loading';
import { useNavigate } from 'react-router-dom'
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const Coverletter_history = () => {
  const { isAuthenticated , getAccessTokenSilently } = useAuth0();
  if (!isAuthenticated) {
    return <div>Please log in to check your coverletter.</div>;
  }
  const location = useLocation();
  data = location.state?.data;
  const index_history = location.state?.index;
  
  //used for testing only
  // const data = require('./data.json');
  // console.log("Debug data location:", location.data);

  const checkJsonObject = (str) => {
    try {
      const parsed = JSON.parse(str);
      return typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed);
    } catch (e) {
      return false;
    }
  };

  if (!checkJsonObject(data.content_history[index_history])) {
    return <div>Sorry, this coverletter history is no longer available due to the update of new coverletter feature. We apologize any inconveniences.</div>;
  }

  const dataObj = JSON.parse(data.content_history[index_history]);
  const coverletter = dataObj.coverletter;

  const [name, setName] = useState(dataObj.name);
  const [email, setEmail] = useState(dataObj.email);
  const [phone, setPhone] = useState(dataObj.phone);
  const [personal_web, setPersonalWeb] = useState(dataObj.personal_web);
  const [company, setCompany] = useState(dataObj.company);
  const [loading, setLoading] = useState(false); // state for loading
  const [apiResponse, setApiResponse] = useState(null);
  const navigate = useNavigate();
  //list of each paragraph
  const paragraphs = coverletter.split('\n');

  const handleCopyToClipboard = async () => {
    try {
        await navigator.clipboard.writeText(coverletter);
        console.log("Text copied to clipboard");
    } catch (err) {
        console.error("Error copying text: ", err);
    }
  }
  const exportStringToPDF = (content) => {
    const doc = new jsPDF({
        format: 'letter',
      });
  
      const marginLeft = 20;
      const marginRight = 20;
      const marginTop = 20;
      const marginBottom = 20;
      const pageWidth = doc.internal.pageSize.width;
      const maxContentWidth = pageWidth - marginLeft - marginRight;
  
      // Add Name section
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text(name, marginLeft, marginTop);
  
      // Add some space
      let y = marginTop + 7;
  
      // Add Contacts section
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
  
      let contactInfo = [];
      if (email) contactInfo.push(`• ${email}`);
      if (phone) contactInfo.push(`• ${phone}`);
      if (personal_web) contactInfo.push(`• ${personal_web}`);
  
      let contactLine = '';
      contactInfo.forEach(info => {
        if (doc.getTextWidth(contactLine + info) > maxContentWidth) {
          doc.text(contactLine.trim(), marginLeft, y);
          y += 6;
          contactLine = info + '   ';
        } else {
          contactLine += info + '   ';
        }
      });
      if (contactLine.trim()) {
        doc.text(contactLine.trim(), marginLeft, y);
        y += 6;
      }
  
      // Add some space
      y += 10;
  
      // Add Company section
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(company, marginLeft, y);
      y += 10;
  
      // Add Cover Letter content
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      const lines = doc.splitTextToSize(content, maxContentWidth);
  
      const lineHeight = 5;
      const pageHeight = doc.internal.pageSize.height;
      const maxPageHeight = pageHeight - marginBottom;
  
      lines.forEach(line => {
        if (y + lineHeight > maxPageHeight) {
          doc.addPage();
          y = marginTop;
        }
        doc.text(line, marginLeft, y);
        y += lineHeight * 1.15;
      });
  
      doc.save('coverletter.pdf');
};

const back_to_profile = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/fetch_resume_history', { //api endpoint
    method: 'POST',
    //---------------------------------auhentication code----------------------------------------
    headers: {
        Authorization: `Bearer ${token}`
    },
    //---------------------------------auhentication code----------------------------------------
    }).then(response => {
    // Check for the 400 status code 
    // console.log('Server Response:', response);
    if (response.status === 400) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
    }
    if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
    }
    //---------------------------------auhentication code----------------------------------------
    if (response.status == 401) {
        throw new Error('Sorry, you must login first')
    }
    //---------------------------------auhentication code----------------------------------------
    return response.json();
    }).then(data => {
    // handle the response data
    //console.log(data);
    //update setapiresponse that is used in result.js
    setApiResponse(data);
    //stop loading and redirect to /result
    setLoading(false);
    //history.push("/result");
    }).catch(error => {
    // handle the error
    console.error(error);
    // If there's an error, ensure loading is turned off
    setLoading(false);
    alert(error.message);  // Displaying the error message to the user
    });
}

  if (loading) {
    // return (
    //     <div className="loading-screen">
    //         <BeatLoader color={"#123abc"} loading={loading} size={40} />
    //         <p>Please wait approximately 90 seconds for the suggestions</p>
    //     </div>
    // );

    return <Loading_mainpage />;

  } else if (apiResponse) {
    //pass the json file to the result.js file
    //console.log("Debug:", apiResponse);

    // return (
    //   <Navigate to={{
    //     pathname: "/results",
    //     state: { data: apiResponse }
    //   }}/>
    // )

    navigate('/profile', { state: { data: apiResponse } });
  }

  return (
    <div className="ClResults">
      <Helmet>
        <title>Coverletter Results - ResuMaster</title>
        <meta property="og:title" content="Coverletter Results - ResuMaster" />
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="ClResults">
        <button className="coverletter-history-back-button" onClick={back_to_profile}>
          <img className="coverletter-history-back-button-img" src='/img/chevron-left.png' />
          <div className="coverletter-history-back-button-text">Return to Home</div>
        </button>
        <div className="ClResults-main">
        {/*============================== temporary comment out for deployment 06/09===================================*/}
         <div className="credits-used-row">
            <div className="credits-used">-50 ResuCredits</div>
          </div> 
        {/*============================== temporary comment out for deployment 06/09===================================*/}
          <div className="title">
            <div className="title-2">View your cover letter</div>
            <Button
                className="export-button"
                onClick={() => exportStringToPDF(coverletter)}
                text="Export"
              />
          </div>
          
          <div className="resumeContent">
            <div className="img-box">
              <img className="copy-img"
                alt="Copy"
                src="/img/copy.png"
                onClick={() => handleCopyToClipboard()} // join array items with new lines
              />
            </div>
            {
              paragraphs.map((paragraph, index) => (
                <p className="paragraph" key={index}>{paragraph}</p>
              ))
            }
          </div>
        </div>
      </div>

    </div>
  );
};

import React, { useEffect } from 'react';


const Education = ({ education, onEducationChange, handleEducationDelete, handleAddEducation, isFieldEmpty, checkEducationFields, setIsEducationFieldsFilled }) => {
    const handleInputChange = (index, field, value) => {
        const updatedEducation = [...education];
        updatedEducation[index] = { ...updatedEducation[index], [field]: value };
        onEducationChange(updatedEducation);
    };

    useEffect(() => {
        setIsEducationFieldsFilled(checkEducationFields(education));
    }, [education, checkEducationFields, setIsEducationFieldsFilled]);

    return (
        <>
        <div className="education-container">
            {(education || []).map((edu, index) => (
                <>
                <div className="summary-header">
                    <div className="edu-title">
                        Education {index + 1}
                    </div>
                    <button onClick={() => handleEducationDelete(index)} className="delete-button">
                        Delete
                    </button>
                </div>
                <div className="edu-content" key={index}>
                    <div className="education-item">
                        <div className="detail-item">
                                <label className="form-label">University/College/School Name<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.university || ''} 
                                onChange={(e) => handleInputChange(index, 'university', e.target.value)} 
                            />
                                {(!edu.university || isFieldEmpty(edu.university)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}
                            
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Degree<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.degrees || ''} 
                                onChange={(e) => handleInputChange(index, 'degrees', e.target.value)} 
                            />
                                {(!edu.degrees ||isFieldEmpty(edu.degrees)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Major</label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.majors || ''} 
                                onChange={(e) => handleInputChange(index, 'majors', e.target.value)} 
                            />
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Program start month (Example: January)<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.university_start_month || ''} 
                                onChange={(e) => handleInputChange(index, 'university_start_month', e.target.value)} 
                            />
                                {(!edu.university_start_month ||isFieldEmpty(edu.university_start_month)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Program start year (Example: 2024)<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.university_start_year || ''} 
                                onChange={(e) => handleInputChange(index, 'university_start_year', e.target.value)} 
                            />
                                {(!edu.university_start_year ||isFieldEmpty(edu.university_start_year)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Program End Month/Expected End Month (Example: January)<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.graduation_month || ''} 
                                onChange={(e) => handleInputChange(index, 'graduation_month', e.target.value)} 
                            />
                                {(!edu.graduation_month ||isFieldEmpty(edu.graduation_month)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Program end year/Expected End Year (Example: 2024)<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.graduation_year || ''} 
                                onChange={(e) => handleInputChange(index, 'graduation_year', e.target.value)} 
                            />
                                {(!edu.graduation_year ||isFieldEmpty(edu.graduation_year)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                            <label className="form-label">GPA</label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.GPA || ''} 
                                onChange={(e) => handleInputChange(index, 'GPA', e.target.value)} 
                            />
                        </div>
                    </div>
                </div>
                </>
            ))}
        </div>
        <div className="button-container">
            <button className="add-certificate-button" onClick={handleAddEducation}>
                Add Education
            </button>
        </div>
        </>
    );
};

export default Education;

import React, { useState } from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const GetStarted = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { isAuthenticated, user } = useAuth0();
  if (!isAuthenticated) {
    return <div>Please log in to start.</div>;
  }
  if (isAuthenticated && !user.email_verified) {
    return <div>Sorry, you need to verify your email first</div>
  }
  //---------------------------------auhentication code----------------------------------------

  const handleNextClick = () => {
    if (selectedOption === 'resume') {
      navigate('/upload');
    } else if (selectedOption === 'coverLetter') {
      navigate('/coverletter_upload');
    }
  };

  return (
    <div className="getStarted-body">
      <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
        
        <title>GetStarted - ResuMaster</title>
        <meta property="og:title" content="GetStarted - ResuMaster" />
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <Link to="/" className="back-button">
        <img className="back-button-img" src='/img/chevron-left.png' />
        <div className="back-button-text">Home</div>
      </Link>
      <div className="get-started-header">
      What would you like to work on today?
      </div>
      <div className="flex-container">
        <div className="flex-content select-container">
            <div 
              className={`card ${selectedOption === 'resume' ? 'selected' : ''}`}
              onClick={() => setSelectedOption('resume')}
            >
              <img className="resume-pic" alt="Resume Revision" 
                src={selectedOption === 'resume' ? "/img/getstarted-resume-purple.png" : "/img/getstarted-resume.png"}/>
              <h3 className="card-title" style={{ color: selectedOption === 'resume' ? '#2a2a2a' : '#aaaaaa' }}>Revise Your Resume</h3>
              {/*============================== temporary comment out for deployment 06/09===================================*/}
              <p className="resume-price">100 ResuCredits</p>
              {/*============================== temporary comment out for deployment 06/09===================================*/}
              <p className="service-description" style={{ color: selectedOption === 'resume' ? '#2a2a2a' : '#aaaaaa' }}>
                We deliver expert recommendations ensure your resume meets the specific requirements of recruiters</p>
            </div>

            <div 
            className={`card ${selectedOption === 'coverLetter' ? 'selected' : ''}`}
            onClick={() => setSelectedOption('coverLetter')}
            >
              <img className="resume-pic" alt="Resume Revision" 
              src={selectedOption === 'coverLetter' ? "/img/getstarted-cl-purple.png" : "/img/getstarted-cl.png"}/>
              <h3 className="card-title" style={{ color: selectedOption === 'coverLetter' ? '#2a2a2a' : '#aaaaaa' }}>Cover Letter Revision</h3>
              {/*============================== temporary comment out for deployment 06/09===================================*/}
              <p className="resume-price">50 ResuCredits</p>
              {/*============================== temporary comment out for deployment 06/09===================================*/}
              <p className="service-description" style={{ color: selectedOption === 'coverLetter' ? '#2a2a2a' : '#aaaaaa' }}>
              We craft personalized cover letters to align with your unique story and your target company value</p>
            </div>
        </div>

      </div>
      <div className="bottom-continue-button">
          <button disabled={!selectedOption} onClick={handleNextClick}
          className={selectedOption ? "flex-content continue-button" : "flex-content continue-button-disabled"}>
          Continue
          </button>

          {/* <Button
            className="button-secondary"
            text="Next"
            style={{alignSelf: "flex-end", marginRight:"13.5%"}}
            onClick={handleNextClick}
          /> */}
        </div>

        {/* <div className="select-container">
          <Option
            title="Revise Your Resume"
            description="Provide us your resume and job description, and we'll deliver expert recommendations to refine your resume, ensuring it meets the specific requirements of recruiters."
            isSelected={selectedOption === "resume"}
            onClick={() => setSelectedOption("resume")}
          />
          <Option
            title="Create Cover Letter"
            description="Crafting personalized cover letters aligned with your unique story and the values of your target company. Unlock your career potential and supercharge your job applications."
            isSelected={selectedOption === "coverLetter"}
            onClick={() => setSelectedOption("coverLetter")}
          />
        </div> */}
          

    </div>
  );
};
// const Option = ({ title, description, isSelected, onClick }) => {
//   return (
//     <div className={`option ${isSelected ? 'selected' : ''}`} onClick={onClick}>
//       <h1>{title}</h1>
//       <p>{description}</p>
//     </div>
//   );
// }
import React, { useRef, useState, useEffect } from 'react'
import { Navigation } from "../../components/Navigation";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Button } from '../../components/Button';
import { Helmet } from 'react-helmet'
import { Loading_CL_revise } from '../Loading/Loading_CL_revise'
import Form from 'react-bootstrap/Form';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

import './style.css'

export const ClInfo = (props) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <div>Please log in to check your coverletter.</div>;
  }
  const navigate = useNavigate();
  //set for loading
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Modern");
  //get the result json from the upload page
  const [cultureValue, setCultureValue] = useState("");
  const [personalStory, setPersonalStory] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [positionTitle, setPositionTitle] = useState("");
  const location = useLocation();
  data = location.state?.data;
  //used for testing
  // const data = require('./data.json');

  // data from the job description
  const degree = useRef(data.degrees);
  const education = useRef(data.education);
  const education_status = useRef(data.education_status);
  const major = useRef(data.majors);
  //the following data will be needed to submit to the revison api endpoint, but not used to autofull
  const [firstName, setFirstName] = useState(data.first_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [email, setEmail] = useState(data.email);
  const [phoneNumber, setPhoneNumber] = useState(data.phone_number);
  const [personalWebsiteUrl, setPersonalWebsiteUrl] = useState(data.personal_website_url);

  const project_experience = data.project_experience;
  const work_experience = data.work_experience;

  //this useEffect code serves to enable the upload button after the user upload both resume and text
  useEffect(() => {
    if (companyName.trim() && positionTitle.trim() && firstName.trim() && lastName.trim() && email.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [companyName, positionTitle, firstName, lastName, email]);

  //varaible for fetching
  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
      }
    //---------------------------------auhentication code----------------------------------------
    // create a FormData object
    const formData = new FormData();
    // append the required data into the form
    //console.log(work_experience);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('phone_number', phoneNumber);
    formData.append('personal_website_url', personalWebsiteUrl);
    formData.append('education', education.current);
    formData.append('degree', degree.current);
    formData.append('major', major.current);
    formData.append('education_status', education_status.current);
    formData.append('personal_story', personalStory);
    formData.append('company_name', companyName);
    formData.append('position_title', positionTitle);
    formData.append('company_value', cultureValue);
    formData.append('style', selectedOption);
    formData.append('work_experience', JSON.stringify(work_experience));
    formData.append('project_experience', JSON.stringify(project_experience));
    formData.append('skills', JSON.stringify(data.skills));
    formData.append('job_description', data.job_description);
    formData.append('required_skills', JSON.stringify(data.required_skills));

    //start loading
    setLoading(true);
    // send a POST request
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    console.log('Send POST request to API')
    fetch('/resumasterapi/revise_coverletter', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
      body: formData
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      return response.json();
    }).then(data => {
      // handle the response data
      //console.log(data);
      //update setapiresponse that is used in result.js
      setApiResponse(data);
      //stop loading and redirect to /result
      setLoading(false);
      //history.push("/result");
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);

      alert(error.message);  // Displaying the error message to the user
      // Redirect to /upload page after the alert
      window.location.href = '/coverletter_upload';
    });
  }

  if (loading) {
    // return (
    //     <div className="loading-screen">
    //         <BeatLoader color={"#123abc"} loading={loading} size={40} />
    //         <p>Please wait approximately 90 seconds for the suggestions</p>
    //     </div>
    // );

    return <Loading_CL_revise />;

  } else if (apiResponse) {
    //pass the json file to the result.js file
    //console.log("Debug:", apiResponse);

    // return (
    //   <Navigate to={{
    //     pathname: "/results",
    //     state: { data: apiResponse }
    //   }}/>
    // )

    //redirect to coverletter results
    navigate('/ClResults', { state: { data: apiResponse } });
  }

  return (
    <div className="ClInfo-container">
      <Helmet>
        <title>Coverletter Info - ResuMaster</title>
        <meta property="og:title" content="Coverletter Info - ResuMaster" />
      </Helmet>
      <header data-role="Header" className="ClInfo-header">
      </header>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="ClInfo">
        <div className="ClInfo-main">
          <div className="title">
            <div className="title-2">Fill In Your Info</div>
          </div>
          <Form>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Target Company Name <span style={{ color: '#B00000' }}>*</span></Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={companyName} onChange={e => setCompanyName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Target Position <span style={{ color: '#B00000' }}>*</span></Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={positionTitle} onChange={e => setPositionTitle(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>First Name <span style={{ color: '#B00000' }}>*</span></Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={firstName} onChange={e => setFirstName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Last Name <span style={{ color: '#B00000' }}>*</span></Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={lastName} onChange={e => setLastName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Email <span style={{ color: '#B00000' }}>*</span></Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={email} onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Phone Number</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Personal Website URL</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={personalWebsiteUrl} onChange={e => setPersonalWebsiteUrl(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Last Attended School</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={education.current} onChange={e => education.current = e.target.value} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Degree</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={degree.current} onChange={e => degree.current = e.target.value} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Major</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={major.current} onChange={e => major.current = e.target.value} />
            </Form.Group>
            <Form.Label className='form-title'>Are you currently in school</Form.Label>
            <Form.Select className="mb-3 form-box" defaultValue={education_status.current} onChange={e => education_status.current = e.target.value}>
              <option>Select from the following options</option>
              <option value="in_progress">Yes</option>
              <option value="graduated">No</option>
            </Form.Select>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Cover Letter Writing Style</Form.Label>
              <div className='option-box'>
                <div className={`option-button ${selectedOption === "Modern" ? 'selected' : ''}`} onClick={() => setSelectedOption("Modern")}>Modern</div>
                <div className={`option-button ${selectedOption === "Creative" ? 'selected' : ''}`} onClick={() => setSelectedOption("Creative")}>Creative</div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Company Value</Form.Label>
              <div className='description-box'>
                <span className="description-text">Please tell us about the companys culture and value (Limited to 100 Characters)</span>
                <span className="description-text counter-text">{`${cultureValue.length}/100`}</span>
              </div>
              <Form.Control as="textarea" rows={5} value={cultureValue} onChange={e => setCultureValue(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3 form-box">
              <Form.Label className='form-title'>Personal Story</Form.Label>
              <div className='description-box'>
                <span className="description-text">Please tell us about a personal story that we can mention in your cover letter (Limited to 1000 Characters)</span>
                <span className="description-text counter-text">{`${personalStory.length}/1000`}</span>
              </div>
              <Form.Control as="textarea" rows={5} value={personalStory} onChange={e => setPersonalStory(e.target.value)} />
            </Form.Group>
          </Form>
          <div className="frame-10">
            <Link to="/">
              <Button className="button-secondary" text="Back" />
            </Link>
            <Button
              className="upload-button"
              onClick={sendToServer}
              disabled={isButtonDisabled}
              text="Submit"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

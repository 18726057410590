/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useCallback, useState } from 'react'
import { Navigation } from "../../components/Navigation";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { Button } from '../../components/Button';
import { Helmet } from 'react-helmet'
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Loading_mainpage } from '../../screens/Loading';
import "./style.css";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const Navigation_loggedin = ({ className }) => {
    //---------------------------------auhentication code----------------------------------------
    /*============================== temporary comment out for deployment 06/09===================================*/
    useEffect(() => {
      sendToServer2();
    }, []);
    /*============================== temporary comment out for deployment 06/09===================================*/
    //auth0 component
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    const [apiResponse, setApiResponse] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // state for loading
    const [credits, setCredits] = useState(""); // Initialize credits state
    const sendToServer = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        //---------------------------------auhentication code----------------------------------------
        //start loading
        setLoading(true);
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/fetch_resume_history', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          //---------------------------------auhentication code----------------------------------------
          if (response.status == 401) {
            throw new Error('Sorry, you must login first')
          }
          //---------------------------------auhentication code----------------------------------------
          return response.json();
        }).then(data => {
          // handle the response data
          //console.log(data);
          //update setapiresponse that is used in result.js
          setApiResponse(data);
          //stop loading and redirect to /result
          setLoading(false);
          //history.push("/result");
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setLoading(false);
          alert(error.message);  // Displaying the error message to the user
        });
      }

      if (loading) {    
        return <Loading_mainpage />;
      } else if (apiResponse) {
        //pass the json file to the result.js file
        //console.log("Debug:", apiResponse);
    
        navigate('/Profile', { state: { data: apiResponse } });
      }
      
      const sendToServer2 = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        //---------------------------------auhentication code----------------------------------------
        //start loading
        //setLoading(true);
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/check_user_status', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          //---------------------------------auhentication code----------------------------------------
          if (response.status == 401) {
            throw new Error('Sorry, you must login first')
          }
          //---------------------------------auhentication code----------------------------------------
          return response.json();
        }).then(data => {
          console.log(credits);
          console.log(data.current_credits);
          if (data.is_exist == true) {
            if (data.in_subscription === true){
                setCredits("Unlimited");
            // console.log(data.in_subscription);
                //console.log(credits);
            }
            else { 
                setCredits(data.current_credits);
                //console.log(data.current_credits);
            }
          } else {
            setCredits("Unavailable");
          }
          setLoading(false);
          //history.push("/result");
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setLoading(false);
          alert(error.message);  // Displaying the error message to the user
        });
    }

    return (
        <div className={`navigation ${className}`}>
        <Link to="/">
        <img
            className="futuristic-modern"
            alt="Futuristic modern"
            src="/img/nav-logo-new.png"
        />
        </Link>

        <div className="navigation-buttons">
            {/* <a className="button-discord" href="https://discord.gg/YURMHY9JNt">
            <img className="discord-icon" src="/img/ic_baseline-discord.png" />
            </a> */}
            
            {/*============================== temporary comment out for deployment 06/09===================================*/}
            <Link className="pricing-tab" to="/plan">
                <div className="button-secondary-text">Pricing</div>
            </Link>
            {/*============================== temporary comment out for deployment 06/09===================================*/}

            <a className="contact-us" href="/contact">
                <div className="button-secondary-text">Contact Us</div>
            </a>

            {/*============================== temporary comment out for deployment 06/09===================================*/}
            <a className="credit-info" onClick={sendToServer}>
              <img className="wallet-icon" src="/img/material-symbols_wallet.png"/>
              <span className="button-secondary-text">ResuCredits: {credits}</span>
            </a>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
            
            <a className="button-discord" onClick={sendToServer}>
            <img className="profile-icon" src="/img/healthicons-ui-user-profile.png" />
            </a>
            
        </div>

        </div>
    );
    };

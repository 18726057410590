import React, { useState, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { FrameWrapper } from "../../components/FrameWrapper";
import "./suggested_style.css";
import { Link } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { Loading } from '../../screens/Loading';
import { useNavigate } from 'react-router-dom'
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const SuggestedEdits = () => {
    //---------------------------------auhentication code----------------------------------------
    //auth0 component
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        return <div>Please log in to view your suggested edits.</div>;
    }
    // for real data
    const location = useLocation();
    data = location.state?.data;
    
    // for testing
    // const data = require('./data.json');
    const work_experience = data.original.work_experience;
    const proj_experience = data.original.project_experience;
    const revise_comments = data.revised_response;
    const company_analysis = data.company_analysis;
    const writting_mode = data.mode;
    const job_description = location.state?.job_description;
    const original_personal_data = {
        personal_info: data.original.basic_info,
        education: data.original.education,
        skills: data.original.skills,
        certificate: data.original.certificate
    };
    const [selections, setSelections] = useState({});
    const [loading, setLoading] = useState(false); // state for loading
    const [apiResponse, setApiResponse] = useState(null);
    const navigate = useNavigate();
    
    // Set checkboxes to be checked initially, except those in aspect6
    useEffect(() => {
        const initialSelections = {};
        work_experience.forEach((_, experienceIndex) => {
            Object.keys(revise_comments[experienceIndex]).forEach(aspectKey => {
                if (aspectKey === 'aspect6') {
                    // Initialize each item in aspect6 array as unchecked
                    revise_comments[experienceIndex][aspectKey].forEach((_, idx) => {
                        initialSelections[`experience-${experienceIndex}-${aspectKey}-${idx}`] = false;
                    });
                } else {
                    initialSelections[`experience-${experienceIndex}-${aspectKey}`] = true;
                }
            });
        });
        setSelections(prevSelections => ({ ...prevSelections, ...initialSelections }));
    }, [work_experience, revise_comments]);

    useEffect(() => {
        const initialSelections_project = {};
        proj_experience.forEach((_, experienceIndex) => {
            Object.keys(revise_comments[work_experience.length + experienceIndex]).forEach(aspectKey => {
                if (aspectKey === 'aspect6') {
                    // Initialize each item in aspect6 array as unchecked
                    revise_comments[work_experience.length + experienceIndex][aspectKey].forEach((_, idx) => {
                        initialSelections_project[`experience-${work_experience.length + experienceIndex}-${aspectKey}-${idx}`] = false;
                    });
                } else {
                    initialSelections_project[`experience-${work_experience.length + experienceIndex}-${aspectKey}`] = true;
                }
            });
        });
        setSelections(prevSelections => ({ ...prevSelections, ...initialSelections_project }));
    }, [proj_experience, revise_comments]);

    // Function to handle checkbox changes, including those in aspect6
    const handleCheckboxChange = (experienceIndex, aspectKey, idx = null) => {
        const key = idx === null
            ? `experience-${experienceIndex}-${aspectKey}`
            : `experience-${experienceIndex}-${aspectKey}-${idx}`;
        setSelections(prevSelections => ({
            ...prevSelections,
            [key]: !prevSelections[key]
        }));
    };

    const getAspectTitle = (aspectKey) => {
        const aspectTitles = {
            'aspect1': 'Metric',
            'aspect2': 'Technical Skills',
            'aspect3': 'Verbs',
            'aspect4': 'Impact',
            'aspect5': 'Soft Skills',
            // Add other aspects if necessary
        };
        return aspectTitles[aspectKey] || aspectKey; // Fallback to aspectKey if no title is found
    };

    const renderAspectContent = (aspectKey, experienceIndex) => {
        const comments = revise_comments[experienceIndex][aspectKey];
        switch (aspectKey) {
            case 'aspect1':
                return `We suggest to add a metric to the experience. Our revision: ${comments}`;
            case 'aspect2':
                return `This experience matches these skills: ${comments.matched}. However, it is missing these keywords mentioned in the job description: ${comments.not_matched}. We will try our best to match these missing keywords.`;
            case 'aspect4':
                return `We suggest to add an achievement to the experience. Our revision: ${comments}`;
            // Add cases for other aspects as necessary
            default:
                return comments; // Default case for simple string comments or other aspect types
        }
    };

    const logCurrentSelections = () => {
        console.log('Current selections:', JSON.stringify(selections));
    };

    const sendToServer = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
          }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();
        // append the required form data that will be sent to the server
        formData.append('user_selections', JSON.stringify(selections))
        formData.append('comments', JSON.stringify(data.revised_response));
        formData.append('jd_key_words', JSON.stringify(data.jd_key_words));
        formData.append('work_experience', JSON.stringify(data.original.work_experience));
        formData.append('project_experience', JSON.stringify(data.original.project_experience));
        formData.append('record_comments', JSON.stringify(data))
        formData.append('mode', writting_mode)
        formData.append('job_description', job_description)
        //start loading
        setLoading(true);
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/revise_exp_comments', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
          body: formData
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          //---------------------------------auhentication code----------------------------------------
          if (response.status == 401) {
            throw new Error('Sorry, you must login first to upload your resume')
          }
          //---------------------------------auhentication code----------------------------------------
          return response.json();
        }).then(data => {
          // handle the response data
          //console.log(data);
          //update setapiresponse that is used in result.js
          setApiResponse(data);
          //stop loading and redirect to /result
          setLoading(false);
          //history.push("/result");
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setLoading(false);
    
          alert(error.message);  // Displaying the error message to the user
          window.location.href = '/upload';
        });
      }

      if (loading) {
        // return (
        //     <div className="loading-screen">
        //         <BeatLoader color={"#123abc"} loading={loading} size={40} />
        //         <p>Please wait approximately 90 seconds for the suggestions</p>
        //     </div>
        // );
    
        return <Loading />;
    
      } else if (apiResponse) {
        //pass the json file to the result.js file
        //console.log("Debug:", apiResponse);
    
        // return (
        //   <Navigate to={{
        //     pathname: "/results",
        //     state: { data: apiResponse }
        //   }}/>
        // )
    
        // navigate('/results', { state: { data: apiResponse, job_description: job_description, company_analysis: company_analysis} });
          navigate('/ResumeResults', { state: { data: apiResponse, job_description: job_description, company_analysis: company_analysis } });
        
        
        //retrive and pass job description & company analysis
        // navigate('/results', { state: {job_description: job_description} });
        // navigate('/results', { state: {company_analysis: company_analysis} });

      }


  return (
    <div className="suggested-page">
      <Helmet>
        <title>Comments - ResuMaster</title>
        <meta property="og:title" content="Comments - ResuMaster" />
        <body className="result_page" />
         <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div class="scanning_title">
        Scanning Result
      </div>
      <p class="text-title">We provide 5 edit perspectives that we will consider in our revision. Please review these perspectives, 
      and uncheck the one you don't want to apply in the revision. Click "Next" after you have done this. We recommend to check them all!</p>
      <div class="company-analysis-heading">
        Company Analysis
      </div>
      <div class="common-block-container">
      {company_analysis !== "null" ? (
        <p className="company-analysis-content">{company_analysis}</p>
        ) : (
        <p className="company-analysis-content">No analysis available.</p>
      )}
      </div>
      
      {
        work_experience.map((experience, experienceIndex) => (
            <div class="grid-container">
                <div class="left-card">
                    <div class="card-header">{experience.job_title} at {experience.company}</div>
                    <div class="left_card_duration">{experience.duration}</div>
                    <ul class="card-list">
                        {experience.job_summary.map((summary, idx) => (
                            <li class="card-list-item" key={idx}>{summary}</li>
                        ))}
                        <div class="added_description">Suggested bullet points (check to add)</div>
                        {revise_comments[experienceIndex]['aspect6'].map((comment, idx) => (
                            <label key={idx} className="checkbox-container">
                                <input 
                                    type="checkbox"
                                    checked={selections[`experience-${experienceIndex}-aspect6-${idx}`]}
                                    onChange={() => handleCheckboxChange(experienceIndex, 'aspect6', idx)}
                                />
                                <span className="checkmark">{comment}</span>
                            </label>
                        ))}
                    </ul>
                </div>
                
                <div className="side-cards-container">
                    {Object.keys(revise_comments[experienceIndex]).map((aspectKey) => {
                        const aspectTitle = getAspectTitle(aspectKey);
                        const aspectContent = renderAspectContent(aspectKey, experienceIndex);
                        if (aspectKey === 'aspect6') {

                        } else {
                            return (
                                <div className="side-card" key={aspectKey}>
                                    <label className="side-card-checkbox">
                                        <input 
                                            type="checkbox"
                                            checked={selections[`experience-${experienceIndex}-${aspectKey}`]}
                                            onChange={() => handleCheckboxChange(experienceIndex, aspectKey)}
                                        />
                                        <span className="side-card-header">{aspectTitle}</span>
                                    </label>
                                    <div className="side-card-text">
                                        {aspectContent}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        ))
      }

      {
        proj_experience.map((experience, experienceIndex) => (
            <div class="grid-container">
                <div class="left-card">
                    <div class="card-header">{experience.project_name} at {experience.institution}</div>
                    <div class="left_card_duration">{experience.duration}</div>
                    <ul class="card-list">
                        {experience.project_description.map((summary, idx) => (
                            <li class="card-list-item" key={idx}>{summary}</li>
                        ))}
                        <div class="added_description">Suggested bullet points (check to add)</div>
                        {revise_comments[work_experience.length + experienceIndex]['aspect6'].map((comment, idx) => (
                            <label key={idx} className="checkbox-container">
                                <input 
                                    type="checkbox"
                                    checked={selections[`experience-${work_experience.length + experienceIndex}-aspect6-${idx}`]}
                                    onChange={() => handleCheckboxChange(work_experience.length + experienceIndex, 'aspect6', idx)}
                                />
                                <span className="checkmark">{comment}</span>
                            </label>
                        ))}
                    </ul>
                </div>
                
                <div className="side-cards-container">
                    {Object.keys(revise_comments[work_experience.length + experienceIndex]).map((aspectKey) => {
                        const aspectTitle = getAspectTitle(aspectKey);
                        const aspectContent = renderAspectContent(aspectKey, work_experience.length + experienceIndex);
                        if (aspectKey === 'aspect6') {

                        } else {
                            return (
                                <div className="side-card" key={aspectKey}>
                                    <label className="side-card-checkbox">
                                        <input 
                                            type="checkbox"
                                            checked={selections[`experience-${work_experience.length + experienceIndex}-${aspectKey}`]}
                                            onChange={() => handleCheckboxChange(work_experience.length + experienceIndex, aspectKey)}
                                        />
                                        <span className="side-card-header">{aspectTitle}</span>
                                    </label>
                                    <div className="side-card-text">
                                        {aspectContent}
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        ))
      }

      <div className="frame-10">
        <Link to="/upload">
          <Button className="button-secondary" text="Back" />
        </Link>

        <Button className="result-button" onClick={sendToServer} text="Next" />

        {/* <button onClick={logCurrentSelections}>Check Status</button> */}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button } from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const Privacy_policy = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { isAuthenticated } = useAuth0();
  //---------------------------------auhentication code----------------------------------------

  return (
    <div className="Privacy_policy">
      <Helmet>
        <title>Privacy Policy - ResuMaster</title>
        <meta property="og:title" content="GetStarted - ResuMaster" />
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <Link to="/" className="back-button">
        <img className="back-button-img" src='/img/chevron-left.png' />
        <div className="back-button-text">Home</div>
      </Link>
      <div className="policy-get-started-header">
        Privacy Policy
      </div>
      <div className="policy-flex-container">
      <p className="document_title">Last Updated: [06/18/2024]</p>

        <p className="document_text">
        Your privacy is important to us. At Craftix AI, Inc., there are a few fundamental principles:
        </p>

        <p className="document_text">
        1. We are thoughtful about the personal information we ask you to provide and the personal information that we collect about you through the operation of our services.
        <br />
        2. We store personal information for only as long as we have a reason to keep it.
        <br />
        3. We aim to make it as simple as possible for you to control what information on your website is shared publicly (or kept private), indexed by search engines, and permanently deleted.
        <br />
        4. We aim for full transparency on how we gather, use, and share your personal information.
        </p>

        <p className="document_title">Who We Are and What This Policy Covers</p>

        <p className="document_text">
        This Privacy Policy applies to information that we collect about you when you use:
        </p>

        <p className="document_text">
        - ResuMaster.ai and all subdomains and domain variants.
        </p>

        <p className="document_title">Deleting Your Data</p>

        <p className="document_text">
        Users can delete data by emailing <a href="mailto:admin@resumaster.ai">admin@resumaster.ai</a> and asking for data to be deleted.
        </p>

        <p className="document_title">Information We Collect</p>

        <p className="document_text">
        We only collect information about you if we have a reason to do so — for example, to provide our Services, to communicate with you, or to make our Services better.
        </p>

        <p className="document_text">
        We collect information in three ways: if and when you provide information to us, automatically through operating our Services, and from outside sources. Now we'll go over what we collect.
        </p>

        <p className="document_title">Information You Provide to Us</p>

        <p className="document_text">
        It's probably no surprise that we collect information that you provide to us. The amount and type of information depends on the context and how we use the information. Here are some examples:
        </p>

        <p className="document_text">
        - <strong>Basic Account Information:</strong> We ask for basic information from you in order to set up your account. For example, we require individuals who sign up for a ResuMaster account to provide an email address — and that's it. You may provide us with more information — like your name — after you create your account, but we don't require that information to create a ResuMaster account.
        <br />
        - <strong>Content Information:</strong> Depending on the Services you use, you may also provide us with information about you in other forms (such as the content on your resume). For example, if you create a resume that includes biographic information about you, we will have that information!
        <br />
        - <strong>Communications With Us:</strong> You may also provide us information when you respond to surveys, or communicate through chat about a support problem.
        </p>

        <p className="document_title">Information We Collect Automatically</p>

        <p className="document_text">
        We also collect some things automatically.
        </p>

        <p className="document_text">
        - <strong>Usage Information:</strong> We collect information about your usage of our Services. For example, we collect information about the actions users perform on ResuMaster — in other words, who did what, when, and to what thing on a site (e.g., [ResuMaster User] created "[title of resume]" at [time/date]). We also collect information about what happens when you use our Services (e.g., page views, support document searches at ResuMaster/help, features used by you, along with information about your device (e.g., screen size, name of cellular network, and mobile device manufacturer). We use this information to, for example, provide our Services to you, as well as get insights on how people use our Services, so we can make our Services better — for example, if we see a surprisingly large group of users are accessing ResuMaster on smartphones, we will work harder on mobile compatibility.
        <br />
        - <strong>Information from Cookies & Other Technologies:</strong> A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Pixel tags (also called web beacons) are small blocks of code placed on websites and emails. ResuMaster uses cookies and other technologies like pixel tags to help us identify and track visitors, usage, and access preferences for our Services, as well as track and understand email campaign effectiveness and to deliver targeted ads for some users.
        </p>

        <p className="document_title">Information We Collect from Other Sources</p>

        <p className="document_text">
        We may also get information about you from other sources. For example, if you create or log into your ResuMaster account through another service (like Google, GitHub, Facebook, or Twitter), we will receive information from that service (such as your username, and some basic profile information) via the authorization procedures used by that service. The information we receive depends on which services you authorize and any options that are available.
        </p>

        <p className="document_title">How And Why We Use Information</p>

        <p className="document_text">
        We use information about you as mentioned above and for the purposes listed below:
        </p>

        <p className="document_text">
        - To provide our Services — for example, to set up and maintain your account, store your resume, or host it at a public URL;
        <br />
        - To further develop and improve our Services — for example by adding new features that we think our users will enjoy or will help them to create a resume or get a job more efficiently;
        <br />
        - To monitor and analyze trends and better understand how users interact with our Services, which helps us improve our Services and make them easier to use;
        <br />
        - To measure, gauge, and improve the effectiveness of our advertising, and better understand user retention and attrition — for example, we may analyze how many individuals upgraded their accounts after receiving a marketing message or the features used by those who continue to use our Services after a certain length of time;
        <br />
        - To monitor and prevent any problems with our Services, protect the security of our Services, detect and prevent fraudulent transactions and other illegal activities, fight spam, and protect the rights and property of Craftix AI, Inc. and others;
        <br />
        - To communicate with you, for example through an email, about offers or promotions related to ResuMaster, to solicit your feedback, or keep you up to date on ResuMaster and our products; and
        <br />
        - To personalize your experience using our Services, provide content recommendations (for example, suggesting a job based on your Resume & experience), target our marketing messages to groups of our users (for example, those who work in a specific field, if entered on their profile), and serve relevant advertisements to some users.
        </p>

        <p className="document_title">Sharing Information</p>

        <p className="document_text">
        We do not sell our users' private personal information.
        </p>

        <p className="document_text">
        We share information about you in the limited circumstances spelled out below and with appropriate safeguards on your privacy:
        </p>

        <p className="document_text">
        - <strong>Employees, and Independent Contractors:</strong> We may disclose information about you to our employees and individuals who are our independent contractors that need to know the information in order to help us provide our Services or to process the information on our behalf. We require our employees and independent contractors to follow this Privacy Policy for personal information that we share with them.
        <br />
        - <strong>Third Party Vendors:</strong> We may share information about you with third party vendors who need to know information about you in order to provide their services to us. This group includes vendors that help us provide our Services to you (like payment providers that process your credit and debit card information (Stripe), email delivery services that help us stay in touch with you, customer chat and email support services that help us communicate with you, those that assist us with our marketing efforts (e.g. by providing tools for identifying a specific marketing target group or improving our marketing campaigns), those that help us understand and enhance our Services (like analytics providers), and companies that make products available on our websites (such as suggested jobs - if you explicitly agree to disclose your information to them), who may need information about you in order to, for example, connect with you to discuss a job.
        <br />
        - <strong>Legal Requests:</strong> We may disclose information about you in response to a subpoena, court order, or other governmental request.
        <br />
        - <strong>Business Transfers:</strong> In connection with any merger, sale of company assets, or acquisition of all or a portion of our business by another company, or in the unlikely event that Craftix AI, Inc. goes out of business or enters bankruptcy, user information would likely be one of the assets that is transferred or acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to apply to your information and the party receiving your information may continue to use your information, but only consistent with this Privacy Policy.
        <br />
        - <strong>With Your Consent:</strong> We may share and disclose information with your consent or at your direction. For example, we may show you a suggested job, and if you consent, we can share your information with a contact at that job so you can discuss interest.
        </p>

        <p className="document_title">Choices</p>

        <p className="document_text">
        You have several choices available when it comes to information about you:
        </p>

        <p className="document_text">
        - <strong>Limit the Information that You Provide:</strong> If you create an account, it's up to you what to include on your profile and content (which is stored by ResuMaster). We only require an email address to create an account — nothing more. The rest is up to you.
        <br />
        - <strong>Opt-Out of Electronic Communications:</strong> You may opt out of receiving promotional messages from us. Just follow the instructions in those messages, or toggle the setting on your account dashboard. If you opt out of promotional messages, we may still send you other messages, like those about your account and legal notices.
        <br />
        - <strong>Set Your Browser to Reject Cookies:</strong> At this time, ResuMaster adheres to "do not track" signals across all of our Services. You can also usually choose to set your browser to remove or reject browser cookies before using ResuMaster's websites, with the drawback that certain features of ResuMaster's websites may not function properly without the aid of cookies.
        <br />
        - <strong>Close Your Account:</strong> While we hate to say goodbye, if you no longer want to use our Services, you can close your ResuMaster account. You can close your account by emailing to admin@resumaster.ai and all of your data will be permanently deleted.
        </p>

        <p className="document_title">Your Rights</p>

        <p className="document_text">
        If you are located in certain countries, including those that fall under the scope of the European General Data Protection Regulation (AKA the "GDPR"), data protection laws give you rights with respect to your personal data, subject to any exemptions provided by the law, including the rights to:
        </p>

        <p className="document_text">
        - Request access to your personal data;
        <br />
        - Request correction or deletion of your personal data;
        <br />
        - Object to our use and processing of your personal data;
        <br />
        - Request that we limit our use and processing of your personal data; and
        <br />
        - Request portability of your personal data.
        </p>

        <p className="document_title">Other Things You May Want to Know</p>

        <p className="document_text">
        Because ResuMaster's Services are offered worldwide, the information about you that we process when you use the Services in the EU may be used, stored, and/or accessed by individuals operating outside the European Economic Area (EEA) who work for us, other members of our group of companies, or third party data processors. This is required for the purposes listed in the How and Why We Use Information section above. When providing information about you to entities outside the EEA, we will take appropriate measures to ensure that the recipient protects your personal information adequately in accordance with this Privacy Policy as required by applicable law. These measures include:
        </p>

        <p className="document_text">
        - In the case of US-based entities, entering into European Commission approved standard contractual arrangements with them, or ensuring they have signed up to the EU-US Privacy Shield; or
        <br />
        - In the case of entities based in other countries outside the EEA, entering into European Commission approved standard contractual arrangements with them.
        </p>

        <p className="document_title">Ads and Analytics Services Provided by Others</p>

        <p className="document_text">
        If we ever decide to place ads on any of our Services, they may be delivered by advertising networks. Other parties may also provide analytics services via our Services. These ad networks and analytics providers may set tracking technologies (like cookies) to collect information about your use of our Services and across other websites and online services. These technologies allow these third parties to recognize your device to compile information about you or others who use your device. This information allows us and other companies to, among other things, analyze and track usage, determine the popularity of certain content, and deliver advertisements that may be more targeted to your interests. Please note this Privacy Policy only covers the collection of information by Craftix AI, Inc. and does not cover the collection of information by any third-party advertisers or analytics providers.
        </p>

        <p className="document_title">Contact Us</p>

        <p className="document_text">
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:admin@resumaster.ai">admin@resumaster.ai</a>.
        </p>

      </div>

    </div>
  );
};
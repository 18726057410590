import React, { useState, useRef, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { FrameWrapper } from "../../components/FrameWrapper";
import './style_results.css'
import { Link } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { Loading_partial } from '../Loading/Loading_partial';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
import ExperienceDetails from "./ExperienceDetails";
import Education from "./Education";

const ResumeResults = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    const location = useLocation();
    const navigate = useNavigate();
    //get the data from the suggested_edits page (the data required to render this page)
    const [data, setData] = useState(location.state?.data);
    const [originalExpData, setOriginalExpData] = useState(null); // Initialize with null
    const [original_data, setOriginalData] = useState(location.state?.original_data);
    const [executive_summary, setExecutiveSummary] = useState(location.state?.executive_summary);
    const [selectedExperience, setSelectedExperience] = useState(location.state?.selectedExperienceIndex);
    //const store_data = location.state?.data.store_data;
    // Effect to set originalExpData on navigation
    useEffect(() => {
        if (location.state?.resetExpCopy) {
            setOriginalExpData(JSON.parse(JSON.stringify(location.state.resetExpCopy)));
        }
    }, [location.state?.resetExpCopy]);


    //info locally needed - original_data info section
    const personal_infor ={
        'First Name': original_data.personal_info.first_name,
        'Last Name': original_data.personal_info.last_name,
        'Email Address': original_data.personal_info.email,
        'Phone Number': original_data.personal_info.phone_number,
        'Location': original_data.personal_info.location,
        'LinkedIn/Personal Website URL':original_data.personal_info.personal_website_url
    }
    const skills=original_data.skills
    const certificates = original_data.certificate;
    const education = original_data.education
   
    //info locally needed - other states section
    const job_description = location.state?.job_description;
    const company_analysis = location.state?.company_analysis;
    const [originalWorkExpLength, setOriginalWorkExpLength] = useState(JSON.parse(JSON.stringify(location.state?.original_work_exp_length)));
    const [work_exp_length, setWorkExpLength] = useState(JSON.parse(JSON.stringify(location.state?.work_exp_length)));
    // Split data into work experience and project experience
    const mode = location.state?.mode;
    const jd_keywords = location.state?.jd_keywords;
    // boolean to check whether the skills section is categorized
    const [showCategorySkills, setShowCategorySkills] = useState(location.state?.isSkillsCategorized);
    const [categorizedSkills, setCategorizedSkills] = useState(location.state?.categorizedSkills);


    const [loading, setLoading] = useState(false); // state for loading
    const [apiResponse, setApiResponse] = useState(null);

    const [showSummary, setShowSummary] = useState(executive_summary !== null);
    

    const [showExperiences, setShowExperiences] = useState(true); // State to toggle showing experiences in sidebar
    
    const [selectedSection, setSelectedSection] = useState('experiences');

    //important info for resume rendering
    const [resumeInfo, setResumeInfo] = useState({
        personal_info: personal_infor,
        executive_summary: executive_summary,
        skills: skills,
        education: education,
        certificate: certificates
    });

    //rawInput is for the uncetagorized skills
    const [rawInput, setRawInput] = useState((resumeInfo.skills || []).join(', '));
    const handleSkillsChange = (updatedSkills) => {
        setRawInput(updatedSkills);  // Store the raw input temporarily
    };

    useEffect(() => {
        if (apiResponse) {
            const summary = apiResponse.executive_summary;
            setExecutiveSummary(summary);
            setResumeInfo((prevState) => ({
                ...prevState,
                executive_summary: summary
            }));
            setShowSummary(true);
        }
    }, [apiResponse]); // This effect depends on `apiResponse`

    const handleSectionClick = (section) => {
        setSelectedSection(section);
        if (section !== 'experiences') {
            setShowExperiences(false)
        }

    };

    const getButtonStyle = (sectionName) => ({
        backgroundColor: selectedSection === sectionName ? '#F0EDFF' : 'transparent',
        color: selectedSection === sectionName ? '#6750A4' : '',
        borderRadius: selectedSection === sectionName ? '40px' : '',
        fontWeight: selectedSection === sectionName ? '600' : ''
    });
    const getExperienceStyle = (index) => ({
        backgroundColor: selectedExperience === index ? '#FAF9FF' : '',
        fontWeight: selectedExperience === index ? '600' : '',
        borderRadius: selectedExperience === index ? '40px' : '',
        color: selectedExperience === index ? 'black' : ''
    });

    // Function to toggle showing experiences in sidebar
    const toggleExperiences = () => {
        setShowExperiences(!showExperiences);
    };
    // Function to check experience fields
    const checkExperienceFields = (experienceDetails) => {
        const requiredFields = ['Title', 'Company', 'Duration'];
        return requiredFields.every(field => experienceDetails[field]);
    };
    
    const handleExperienceChange = (current_experience, index) => {
        setData(prevData => {
          // Create a new rendering_data array with the updated value at the specified index
          const newRenderingData = [...prevData.rendering_data];
          newRenderingData[index].Experience = current_experience;
      
          // Return a new object with the updated rendering_data
          return {
            ...prevData,
            rendering_data: newRenderingData
          };
        });
        
      };

    const handleExperienceDelete = (index) => {
        const updatedExperience = data.rendering_data.filter((_, i) => i !== index);

        // Adjust selectedExperience if necessary
        if (selectedExperience >= (updatedExperience.length)) {
            setSelectedExperience(Math.max(updatedExperience.length - 1, 0));
        }

        // Adjust the work experience length accordingly
        if (index < work_exp_length) {
            setWorkExpLength(currentLength => currentLength - 1);
        }
        // Update the resumeInfo and originalData states
        setData((prevState) => ({
            ...prevState,
            rendering_data: updatedExperience
        }));
    };

    const handleExperienceReset = () => {
        setSelectedExperience(0);
        setWorkExpLength(originalWorkExpLength);
        // Update the resumeInfo and originalData states
        setData((prevState) => ({
            ...prevState,
            rendering_data: JSON.parse(JSON.stringify(originalExpData))
          }));
    };

    const handlePersonalInfoChange = (key, value) => {
        setResumeInfo((prevState) => ({
            ...prevState,
            personal_info: {
                ...prevState.personal_info,
                [key]: value
            }
        }));
        if (key === "First Name") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    first_name: value
                }
            }));
        } else if (key === "Last Name") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    last_name: value
                }
            }));
        } else if (key === "Email Address") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    email: value
                }
            }));
        } else if (key === "Phone Number") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    phone_number: value
                }
            }));
        } else if (key === "Location") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    location: value
                }
            }));
        } else if (key === "LinkedIn/Personal Website URL") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    personal_website_url: value
                }
            }));
        }
    };

    const handleSummaryChange = (summary) => {
        setResumeInfo((prevState) => ({
            ...prevState,
            executive_summary: summary
        }));
        setExecutiveSummary(summary);
    };

    const handleSummaryDelete = () => {
        setShowSummary(false);
        setResumeInfo((prevState) => ({
            ...prevState,
            executive_summary: null
        }));
        setExecutiveSummary(null);
    };

    const handleEducationChange = (updatedEducation) => {
        setResumeInfo((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
    };

    const handleEducationDelete = (index) => {
        const updatedEducation = resumeInfo.education.filter((_, i) => i !== index);

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
    };

    const handleAddEducation = () => {
        const neweducation = [...(resumeInfo.education || []), { GPA: '', degrees: '', graduation_month: '', graduation_year: '', majors: '', university: '', university_start_month: '', university_start_year: '' }];

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            education: neweducation,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            education: neweducation,
        }));
    };

    const processSkills = () => {
        const new_skills_list = rawInput
            .split(',')
            .map(skill => skill.trim())
            .filter(skill => skill.length > 0);
    
        setResumeInfo((prevState) => ({
            ...prevState,
            skills: new_skills_list,
        }));
    
        setOriginalData((prevState) => ({
            ...prevState,
            skills: new_skills_list,
        }));
    };

    const handleAddCertificate = () => {
        const newCertificates = [...(resumeInfo.certificate || []), { name: '', achieved_date: '' }];

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            certificate: newCertificates,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: newCertificates,
        }));
    };

    const handleCertificateChange = (index, key, value) => {
        const updatedCertificates = [...resumeInfo.certificate];
        updatedCertificates[index][key] = value;
        setResumeInfo((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
    };

    const handleDeleteCertificate = (index) => {
        const updatedCertificates = resumeInfo.certificate.filter((_, i) => i !== index);

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
    };
    const isFieldEmpty = (value) => value === '';
    const areRequiredFieldsFilled = () => {
        return resumeInfo.personal_info['First Name'] &&
            resumeInfo.personal_info['Last Name'] &&
            isEducationFieldsFilled &&
            isExperienceFieldsFilled;

    };
    // State to manage experience fields fill status
    const [isExperienceFieldsFilled, setIsExperienceFieldsFilled] = useState(true);
    
    // State to manage education fields fill status
    const [isEducationFieldsFilled, setIsEducationFieldsFilled] = useState(true);

    // Function to check education fields
    const checkEducationFields = (education) => {
        return education.every(edu => edu.university && edu.degrees && edu.university_start_month && edu.university_start_year && edu.graduation_month && edu.graduation_year);
    };

    const handlePreview = () => {
        navigate('/resume-preview', { state: { data: data, 
                                               job_description: job_description, 
                                               company_analysis: company_analysis, 
                                               original_data: original_data, 
                                               work_exp_length: work_exp_length, 
                                               original_work_exp_length: originalWorkExpLength,
                                               mode: mode, 
                                               executive_summary: executive_summary,
                                               resetExpCopy: originalExpData,
                                               jd_keywords: jd_keywords,
                                               isSkillsCategorized: showCategorySkills,
                                               categorizedSkills: categorizedSkills
                                            } });
    };

    const sendToServer = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        //---------------------------------auhentication code----------------------------------------

        // create a FormData object
        const formData = new FormData();
        // append the file and the job description
        formData.append('job_description', job_description);
        formData.append('company_description', company_analysis);

        // loop through resume data
        const resume = [];
        for (let i = 0; i < data.length - 1; i++) {
            const myObject = {};
            myObject["title"] = data.rendering_data[i].Experience.Title;
            myObject["duration"] = data.rendering_data[i].Experience.Duration;
            myObject["company"] = data.rendering_data[i].Experience.Company;
            myObject["location"] = data.rendering_data[i].Experience.Location;
            myObject["content"] = data.rendering_data[i].Experience.Responsibilities;
            resume.push(myObject);
        }

        formData.append('resume', resume);

        //start loading
        setLoading(true);
        // send a POST request
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/executive_summary', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first to upload your resume')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            //console.log(data)
            //update setapiresponse that is used in result.js
            setApiResponse(data);
            //console.log(apiResponse);
            //stop loading and redirect to /result
            setLoading(false);
            //history.push("/result");
        }).catch(error => {
            // handle the error
            console.error(error);
            // If there's an error, ensure loading is turned off
            setLoading(false);

            alert(error.message);  // Displaying the error message to the user
        });
    }

    const handleExperienceClick = (index) => {
        setSelectedExperience(index);

        // Perform the check synchronously
        const allExperiencesFilled = data?.rendering_data.every(exp => checkExperienceFields(exp.Experience));
        // Update the state
        setIsExperienceFieldsFilled(allExperiencesFilled);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loadingCategory, setLoadingCategory] = useState(false); // state for loading
    const [apiResponseCategory, setApiResponseCategory] = useState(null);
    const [rawCategorizedInputs, setRawCategorizedInputs] = useState(null);
    const dropdownRef = useRef(null);
    
    const categories = ["Design", "Research", "Development", "Data Analysis", "Tech Skills", "Soft Skills", "Management", "Marketing", "Language"];
    
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsOpen(!isOpen);
    };
    
    const handleCategoryChange = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(item => item !== category));
        } else if (selectedCategories.length < 3) {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const isSaveDisabled = selectedCategories.length < 2;

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setIsOpen(false);
        }
    };

    const send_to_server_keywords_category = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();

        let combined_categorized_skills_send_server = [];
        if (showCategorySkills) {
            combined_categorized_skills_send_server = combine_category_skills();
        } else {
            combined_categorized_skills_send_server = resumeInfo.skills;
        }

        formData.append('category_list', JSON.stringify(selectedCategories));
        formData.append('keywords', JSON.stringify(combined_categorized_skills_send_server));
        setIsDropdownOpen(false);
        setLoadingCategory(true);
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/categorize_keywords', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first to upload your resume')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            setApiResponseCategory(data);
            setLoadingCategory(false);
        }).catch(error => {
            // handle the error
            console.error(error);
            // If there's an error, ensure loading is turned off
            setLoadingCategory(false);
            alert(error.message);
        });
    };

    const handleCategorizedSkillsChange = (key, updatedSkills) => {
        setRawCategorizedInputs(prevState => ({
            ...prevState,
            [key]: updatedSkills,
        }));
    };

    const processCategorizedSkills = (key) => {
        const new_skills_list = rawCategorizedInputs[key]
            .split(',')
            .map(skill => skill.trim())
            .filter(skill => skill.length > 0);
    
        setCategorizedSkills(prevState => ({
            ...prevState,
            [key]: new_skills_list,
        }));
    };

    const resetCategory = () => {
        const combined_categorized_skills = combine_category_skills();
    
        setResumeInfo((prevState) => ({
            ...prevState,
            skills: combined_categorized_skills,
        }));
    
        setOriginalData((prevState) => ({
            ...prevState,
            skills: combined_categorized_skills,
        }));
        setCategorizedSkills(null);
        setApiResponse(null);
        setShowCategorySkills(false);
        setIsOpen(false);
    };

    const combine_category_skills = () => {
        const combined_categorized_skills = Array.from(
            new Set(
                Object.keys(categorizedSkills)
                    .flatMap(key => categorizedSkills[key]) // Flatten the lists into one array
            )
        );
        return combined_categorized_skills;
    }

    useEffect(() => {
        if (apiResponseCategory) {
            const categorized_skills = apiResponseCategory;
            setCategorizedSkills(categorized_skills);
            setShowCategorySkills(true);
            setApiResponseCategory(null);
        }
    }, [apiResponseCategory]); // This effect depends on `apiResponse`

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    useEffect(() => {
        if (resumeInfo.skills) {
            setRawInput((resumeInfo.skills || []).join(', '));
        }
    }, [resumeInfo.skills]);

    useEffect(() => {
        if (showCategorySkills && categorizedSkills) {
            setRawCategorizedInputs(
                Object.keys(categorizedSkills).reduce((acc, key) => {
                    acc[key] = (categorizedSkills[key] || []).join(', ');
                    return acc;
                }, {})
            );
        } else {
            setRawCategorizedInputs(null);
        }
    }, [categorizedSkills, showCategorySkills]);

    useEffect(() => {
        // Check education and experience fields when data changes
        const educationFilled = checkEducationFields(resumeInfo.education);
        const allexperienceFilled = data?.rendering_data?.every(exp => checkExperienceFields(exp.Experience));

        setIsEducationFieldsFilled(educationFilled);
        setIsExperienceFieldsFilled(allexperienceFilled);
    }, [resumeInfo.education, data, isExperienceFieldsFilled]);
    return (
        isAuthenticated ? (
            <div className="resu-results">
                <Helmet>
                    {/* <!-- Google tag (gtag.js) --> */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                        <script>
                        {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-F812QPTDD9');
                        `}
                        </script>
                    <title>ResumeResults - ResuMaster</title>
                    <meta property="og:title" content="Results - ResuMaster" />
                    <body className="result_page" />
                    <script src="//embed.typeform.com/next/embed.js"></script>
                </Helmet>
                {
                    isAuthenticated ? (
                        <Navigation_loggedin className="navigation-2202" />
                    ) : (
                        <Navigation className="navigation-2202" />
                    )
                }

                <div className="contents">
                   
                    < div className="main-box">                        
                        {selectedSection === 'personalDetails' && (
                            <div className="personal-content">

                                {resumeInfo.personal_info && Object.entries(resumeInfo.personal_info).map(([key, value]) => (
                                    <div key={key} className="detail-item-personal">
                                        <label className="form-label">
                                            {key}
                                            {(key === "First Name" || key === "Last Name") && <span className="starColor">*</span>}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-input"
                                            value={value || ''}
                                            onChange={(e) => handlePersonalInfoChange(key, e.target.value)}
                                        />
                                        {((key === "First Name" || key === "Last Name") && !value) && (
                                            <div className="hint-text">Hint: this content is required.</div>
                                        )}
                                    </div>
                                ))}

                            </div>
                        )}
                        {selectedSection === 'summary' && (
                            <div className="summary-container">
                                <div className="inner-container">
                                    {loading ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <Loading_partial />
                                            <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600 }}>Loading...</p>
                                        </div>
                                       
                                    ) : showSummary ? (
                                            <div className="summary-content ">
                                                <div className="summary-header">
                                                    <div className="summary-title">
                                                        Summary
                                                    </div>
                                                    <button onClick={handleSummaryDelete} className="delete-button">
                                                        Delete
                                                    </button>
                                                </div>
                                                <textarea
                                                    className="summary-textarea"
                                                    value={resumeInfo.executive_summary}
                                                    onChange={(e) => handleSummaryChange(e.target.value)}
                                                />
                                            </div>
                                    ) : (
                                        <Button onClick={sendToServer}  className='generate_button' text="Generate an Executive Summary based on Revision"/>
                                    )}
                                </div>
                            </div>
                        )}
                        {selectedSection === 'education' && (
                            <Education education={resumeInfo.education}
                                       onEducationChange={handleEducationChange} 
                                       handleEducationDelete={handleEducationDelete}
                                       handleAddEducation={handleAddEducation}
                                       isFieldEmpty={isFieldEmpty} 
                                       checkEducationFields={checkEducationFields}
                                       setIsEducationFieldsFilled={setIsEducationFieldsFilled}
                        />
                        )}
                        {selectedSection === 'experiences' && (
                            <ExperienceDetails
                                data={data}
                                job_description = {job_description}
                                company_analysis = {company_analysis}
                                original_data = {original_data}
                                work_exp_length = {work_exp_length}
                                original_work_exp_length = {originalWorkExpLength}
                                mode={mode}
                                executive_summary = {executive_summary}
                                selectedExperience={selectedExperience}
                                handleExperienceChange = {handleExperienceChange}
                                handleExperienceDelete = {handleExperienceDelete}
                                resetExpCopy = {originalExpData}
                                jd_keywords = {jd_keywords}
                                isFieldEmpty={isFieldEmpty}
                                checkExperienceFields={checkExperienceFields}
                                setIsExperienceFieldsFilled={setIsExperienceFieldsFilled}
                                isSkillsCategorized = {showCategorySkills}
                                categorizedSkills = {categorizedSkills}
                            />
                        )}
                        {selectedSection === 'skills' && (
                            <div className="skills-container">
                                <div className="detail-item-skills">
                                    <label className="form-label">Keywords from Job Description</label>
                                    <div className="jd-keywords-frame">
                                        {jd_keywords.map((keyword, index) => (
                                            <FrameWrapper key={index} className="frame-2213" text={keyword} />
                                        ))}
                                    </div>
                                </div>
                                <div className="skills-category-container" ref={dropdownRef}>
                                    <div className="skills-category-button-container">
                                        <div className={`skills-category-button ${isOpen ? 'active' : ''}`} onClick={toggleDropdown}>
                                            <img src="/img/category-sort.png" alt="icon" className="sort-icon" />
                                            Group Skills By Category
                                        </div>
                                        {showCategorySkills && (
                                            <div className="reset-category-button" onClick={resetCategory}>
                                                Reset Category
                                            </div>
                                        )}
                                    </div>
                                        {isDropdownOpen && (
                                            <div className="dropdown-list">
                                                <div className="dropdown-list-header">Categories</div>
                                                <div className="dropdown-list-guide">
                                                    You can assign up to 3 categories to your skills. Once selected, our system will automatically sort your skills into the chosen categories.
                                                </div>
                                                {categories.map((category) => (
                                                    <label key={category} className="category-dropdown-item">
                                                        <input
                                                            type="checkbox"
                                                            value={category}
                                                            checked={selectedCategories.includes(category)}
                                                            onChange={() => handleCategoryChange(category)}
                                                        />
                                                        <span className="checkmark"></span>
                                                        {category}
                                                    </label>
                                                ))}
                                                <button
                                                    className="save-category-button"
                                                    onClick={send_to_server_keywords_category}
                                                    disabled={isSaveDisabled}
                                                >
                                                    Save Category
                                                </button>
                                            </div>
                                        )}
                                </div>
                                <div className="detail-item-skills">
                                    {loadingCategory ? (
                                            <div style={{ textAlign: 'center' }}>
                                                <Loading_partial />
                                                <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600 }}>Loading...</p>
                                            </div>
                                            ) : showCategorySkills && rawCategorizedInputs ? (
                                                Object.keys(categorizedSkills).map(key => (
                                                    <div className="selected-category-skill" key={key}>
                                                        <label className="form-label">{key}</label>
                                                        <textarea
                                                            className="form-input"
                                                            value={rawCategorizedInputs ? (rawCategorizedInputs[key] || '') : ''}
                                                            onChange={(e) => handleCategorizedSkillsChange(key, e.target.value)}
                                                            onBlur={() => processCategorizedSkills(key)}  // Process skills when the textarea loses focus
                                                            rows={3}
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <>
                                                    <label className="form-label">Skills (edit in a common-separated format)</label>
                                                    <textarea
                                                        className="form-input"
                                                        value={rawInput}
                                                        onChange={(e) => handleSkillsChange(e.target.value)}
                                                        onBlur={processSkills}  // Process skills when the textarea loses focus
                                                        rows={10}
                                                    />
                                                </>
                                    )}
                                </div>
                            </div>                         
                        )}
                        {selectedSection === 'certificates' && (
                        <>
                            {resumeInfo.certificate && resumeInfo.certificate.length > 0 && resumeInfo.certificate.map((certificate_element, index) => (
                                <>
                                    <div className="certificate-header">
                                        <div className="certificate-title">
                                            Certificate {index + 1}
                                        </div>
                                        <button onClick={() => handleDeleteCertificate(index)} className="delete-button">
                                            Delete
                                        </button>
                                    </div>
                                    <div className="certificate-container" key={index}>
                                        <label className="form-label">Certificate Name</label>
                                        <input
                                            type="text"
                                            className="experience-form-input"
                                            value={certificate_element.name || ''}
                                            onChange={(e) => handleCertificateChange(index, 'name', e.target.value)}
                                            maxLength={80}
                                        />
                                        <label className="form-label">Achieved Date</label>
                                        <input
                                            type="text"
                                            className="experience-form-input"
                                            value={certificate_element.achieved_date || ''}
                                            onChange={(e) => handleCertificateChange(index, 'achieved_date', e.target.value)}
                                            maxLength={20}
                                        />
                                    </div>
                                </>
                            ))}

                                <div className="button-container">
                                    <button className="add-certificate-button" onClick={handleAddCertificate}>
                                        Add Certificate
                                    </button>
                                </div>
                                    </>
                                )}
                    </div>

                    <div className="sidebar">
                        <h3>Resume</h3>
                        <div className="nextStep">
                            <button className={`view-full ${!areRequiredFieldsFilled() ? 'disabled' : ''}`}
                                onClick={handlePreview}
                                disabled={!areRequiredFieldsFilled()}
                            >View full resume</button>
                            {!areRequiredFieldsFilled() && (
                                <p className="hint-message">Hint: please check your required fields</p>
                            )}

                        </div>
                        
                        <div className="side-list">
                            <button
                                onClick={() => handleSectionClick('personalDetails')}
                                style={getButtonStyle('personalDetails')}
                                className={areRequiredFieldsFilled() ? '' : 'experience-button'}
                            >
                                <img alt="resume_personalinformation" src={selectedSection === 'personalDetails' ? "/img/resume_personal_purple.png" : "/img/resume_personalinformation.png"} />
                                Personal Details
                            </button>

                            <button 
                                onClick={() => {
                                    handleSectionClick('summary')
                                    
                                }}
                                style={getButtonStyle('summary')}
                            >
                                <img alt="resume_summary" src={selectedSection === 'summary' ? "/img/resume_purple_summary.png" : "/img/resume_summary.png" }/>
                                Summary</button>

                            <button 
                                onClick={() => handleSectionClick('education')}
                                style={getButtonStyle('education')}
                            >
                                <img alt="resume_educationn" src={selectedSection === 'education' ? "/img/resume_purple_education.png" :"/img/resume_education.png" }/>
                                Education</button>

                            <button  onClick={()=>{
                                handleSectionClick('experiences');
                                toggleExperiences();
                            }}
                            style={getButtonStyle('experiences')}
                            >
                                <img alt="resume_experience" src={selectedSection === 'experiences' ? "/img/resume_purple_experience.png" : "/img/resume_experience.png" }/>
                                Experiences</button>
                            <ul>
                                {showExperiences && data.rendering_data && data.rendering_data.map((experience, index) => (
                                    <li key={index}>
                                        <button onClick={() => { handleExperienceClick(index) }} 
                                            style={getExperienceStyle(index)}>
                                            {(experience.Experience.Title || '')} at {(experience.Experience.Company || '')}
                                        </button>
                                    </li>
                                ))}
                                {showExperiences && <button className="add" onClick={handleExperienceReset}>Restore All</button>}
                            </ul>
                           
                            <button 
                                onClick={() => handleSectionClick('skills')}
                                style={getButtonStyle('skills')}
                            >
                                <img alt="resume_skills" src={selectedSection === 'skills' ? "/img/resume_purple_skills.png" : "/img/resume_skills.png" }/>
                                Skills</button>

                            <button 
                                onClick={() => handleSectionClick('certificates')}
                                style={getButtonStyle('certificates')}
                            >
                                <img alt="certificate" src={selectedSection === 'certificates' ? "/img/resume_purple_certificate.png" : "/img/resume_certificate.png" }/>
                                Certificates</button>
                        </div>
                    </div>
                </div>
            </div >
        ) : (
            <div>Please log in first</div>
        ))
};

export default ResumeResults;

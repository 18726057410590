import React, { useEffect, useState, useMemo } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { Helmet } from 'react-helmet';
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Loading_mainpage } from '../../screens/Loading';
//---------------------------------authentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------authentication code----------------------------------------

export const RevisionHistoryPDF = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfBlob, setPdfBlob] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(1); // Set default selected template to 0
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // state for loading
  const [apiResponse, setApiResponse] = useState(null);

  // Error handling function
  const handleError = (error) => {
    console.error(error);
    alert('The history recording is no longer available');
    navigate('/profile');
  };
  try {
  // for data get from the database
    const data_received = location.state?.data;
    const index_history = location.state?.index;
    const executive_summary_DB = data_received.executive_summary[index_history];
    const data_DB = useMemo(() => JSON.parse(data_received.results[index_history]), [data_received, index_history]);
    console.log(data_DB);

  // for real dynamic data
    const [exp_data, setExpData] = useState(data_DB.expData);
    const [original_data, setOriginalData] = useState(data_DB.originalData);
    const [executive_summary, setExecutiveSummary] = useState(
        executive_summary_DB === "NULL" ? null : executive_summary_DB
    );
    const work_exp_length = data_DB.workExpLength;
    const [isSkillsCategorized, setIsSkillsCategorized] = useState(original_data.isSkillsCategorized);
    const [categorizedSkills, setCategorizedSkills] = useState(original_data.categorizedSkills);
    console.log(isSkillsCategorized)
    console.log(categorizedSkills)
    console.log(original_data);
    // console.log(executive_summary);
    // console.log(exp_data);

    //info locally needed - original_data info section
    const personal_infor ={
        'First Name': original_data.personal_info.first_name,
        'Last Name': original_data.personal_info.last_name,
        'Email Address': original_data.personal_info.email,
        'Phone Number': original_data.personal_info.phone_number,
        'Location': original_data.personal_info.location,
        'LinkedIn/Personal Website URL':original_data.personal_info.personal_website_url
    }
    const skills=original_data.skills
    const certificates = original_data.certificate;
    const education = original_data.education

    //info locally needed - other states section
    
    // Split data into work experience and project experience
    const work_experience = exp_data.slice(0, work_exp_length);
    const project_experience = exp_data.slice(work_exp_length);


    //important info for resume rendering
    const [data, setData] = useState({
        personal_info: personal_infor,
        executive_summary: executive_summary,
        skills: skills,
        education: education,
        certificate: certificates,
        revised_work_exp: work_experience,
        revised_proj_exp: project_experience
    });

  console.log(data)
  // for test only
//   const data = require('./data.json');
  const name = (data.personal_info['First Name'] || '') + " " + (data.personal_info['Last Name'] || '');
  const email = data.personal_info['Email Address'] || '';
  const phone_number = data.personal_info['Phone Number'] || '';
  const personal_website = data.personal_info['LinkedIn/Personal Website URL'] || '';
  const user_location = data.personal_info['Location'] || '';
  const summary = data.executive_summary;
  const education_list = data.education;
  const revisedWorkExp = data.revised_work_exp ?? [];
  const revisedProjExp = data.revised_proj_exp ?? [];
  const experiences = [...revisedWorkExp, ...revisedProjExp];

  const fontsize = 11;
  const name_fontsize = 14;
  const template_1_section_header = 13;
  const name_fontsize_template_3 = 25;


  const generateTemplate1 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const createPage = () => {
        const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
        return page;
      };

      let page = createPage();
      let { width, height } = page.getSize();

      const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
        // Ensure text is not null or undefined
        const textToDraw = text || '';
    
        if (y < 50) {
            page = createPage();
            y = height - 50;
        }
        page.drawText(textToDraw, { x, y, size, font, color });
        return y;
    };

      const drawSectionHeader = (text, y) => {
        y = drawText(text, 50, y, template_1_section_header, timesRomanBoldFont, rgb(0, 0, 0));
        return y - 20;
      };

      const drawBulletedText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
        const bullet = '• ';
        const bulletWidth = font.widthOfTextAtSize(bullet, size);
        const indent = x + bulletWidth; // Position for the subsequent lines
        const words = text.split(' ');
        let line = '';
        let firstLine = true;

        words.forEach(word => {
          const testLine = line + word + ' ';
          const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

          if (testLineWidth < maxWidth) {
            line = testLine;
          } else {
            y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
            y -= size + 4;
            line = word + ' ';
            firstLine = false;
          }
        });

        y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
        return y - size - 4;
      };

      const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
        const words = text.split(' ');
        let line = '';
        let lines = [];
        words.forEach(word => {
          if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
            line += word + ' ';
          } else {
            lines.push(line);
            line = word + ' ';
          }
        });
        lines.push(line);
        lines.forEach((line, index) => {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
          y -= size + 4;
        });
        return y;
      };

      const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
        const words = text.split(' ');
        let line = '';
        let lines = [];
        words.forEach(word => {
          if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
            lines.push(line);
            line = word + ' ';
          } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
            line += word + ' ';
          } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
            line += word + ' ';
          } else {
            lines.push(line);
            line = word + ' ';
          }
        });
        lines.push(line);
        lines.forEach((line, index) => {
          if (index === 0) {
            y = drawText(line, x, y, size, font, rgb(0, 0, 0));
          } else {
            y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
          }
          y -= size + 4;
        });
        return y;
      };

      const drawTextWithWrappingSkillsCategoryKey = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
        const words = text.split(' ');
        let line = '';
        let lines = [];
        words.forEach(word => {
          if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
            lines.push(line);
            line = word + ' ';
          } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
            line += word + ' ';
          } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
            line += word + ' ';
          } else {
            lines.push(line);
            line = word + ' ';
          }
        });
        lines.push(line);
        lines.forEach((line, index) => {
          if (index === 0) {
            y = drawText(line, x, y, size, font, rgb(0, 0, 0));
          } else {
            y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
          }
        });
        return y;
      };

      // Header
      // Construct the header information dynamically
        const parts = [email, phone_number, user_location, personal_website].filter(item => item);
        const headerInfo = parts.join(' | ');

        // Header
        let yPosition = height - 40;
        yPosition = drawText(name || '', 50, yPosition, name_fontsize, timesRomanBoldFont);

        // Only draw the header info if it's not empty
        if (headerInfo) {
            yPosition = drawText(headerInfo, 50, yPosition - 20, fontsize);
        }

      // Summary
      if (summary) {
        yPosition = drawTextWithWrapping(summary, 50, yPosition - 30, fontsize, timesRomanFont);
        // Add extra space after the summary
        const extraSpace = 10;
        yPosition -= extraSpace;
      } else {
        yPosition -= 30;
      }

      // Education
      if (education_list && education_list.length > 0) {
        yPosition = drawSectionHeader('Education', yPosition);
        education_list.forEach((edu, index) => {
            yPosition = drawText(edu.university, 50, yPosition, fontsize, timesRomanBoldFont);

            // Calculate the position for the duration to be right-aligned
            const durationText = `${edu.university_start_month} ${edu.university_start_year} - ${edu.graduation_month} ${edu.graduation_year}`;
            const durationTextWidth = timesRomanFont.widthOfTextAtSize(durationText, fontsize);
            yPosition = drawText(durationText, width - durationTextWidth - 50, yPosition, fontsize);

            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            yPosition = drawText(degree_major_text, 50, yPosition - 20, fontsize);

            if (edu.GPA) {
                yPosition = drawText(`GPA: ${edu.GPA}`, 50, yPosition - 20, fontsize);
            }

            // Check if the current element is the last one
            if (index === education_list.length - 1) {
                yPosition -= 26;
              } else {
                yPosition -= 20;
              }
        });
      }
      

      // Experience
      if (experiences && experiences.length > 0) {
        yPosition = drawSectionHeader('Experience', yPosition);

        experiences.forEach(exp => {
            const title_text = exp.Experience.Title + " at " + exp.Experience.Company;
            yPosition = drawText(title_text, 50, yPosition, fontsize, timesRomanBoldFont);
            if (exp.Experience.Location) {
                yPosition = drawText(exp.Experience.Location, 50, yPosition - 20, fontsize);
            }
            if (exp.Experience.Duration) {
                const expDurationTextWidth = timesRomanFont.widthOfTextAtSize(exp.Experience.Duration, fontsize);
                yPosition = drawText(exp.Experience.Duration, width - expDurationTextWidth - 50, yPosition, fontsize);
            }
            yPosition -= 20;
            exp.Experience.Responsibilities.forEach(resp => {
            yPosition = drawBulletedText(resp, 50, yPosition);
            });
            yPosition -= 10;
        });
      }
      
      // Skills
      if (skills && skills.length > 0) {
        // Add 20 vertical spacing before Skills section
        if (isSkillsCategorized) {
            yPosition = drawSectionHeader('Skills', yPosition);
            let lastValidIndex = -1;
            // First, identify the last valid index
            Object.keys(categorizedSkills).forEach((key, index) => {
                if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                    lastValidIndex = index;
                }
            });

            Object.keys(categorizedSkills).forEach((key, index) => {
                // Check if the value is an empty list or null
                if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                    return; // Skip to the next iteration
                }

                let skillsText = categorizedSkills[key].join(', ');
                keyText = key + ': ';
                yPosition = drawTextWithWrappingSkillsCategoryKey(keyText, 50, yPosition, fontsize, timesRomanBoldFont);
                yPosition = drawTextWithWrappingSkillsCategory(skillsText, 50 + timesRomanBoldFont.widthOfTextAtSize(keyText, fontsize), yPosition, fontsize, timesRomanFont);

                // Check if the current key is the last valid element in categorizedSkills
                if (index === lastValidIndex) {
                    yPosition -= 10;
                }
            });
        } else {
            yPosition = drawSectionHeader('Skills', yPosition);
            const skillsText = skills.join(', ');
            yPosition = drawTextWithWrapping(skillsText, 50, yPosition, fontsize, timesRomanFont);
            yPosition -= 10;
        }
      }
      
      // Certificate: Add 20 vertical spacing before Certificates section
      if (certificates && certificates.length > 0) {
        // Certificates
        yPosition = drawSectionHeader('Certificates', yPosition);
        certificates.forEach(cert => {
            const certNameText = '• ' + (cert.name || '');
            const certDateText = cert.achieved_date || '';
            const certDateTextWidth = timesRomanFont.widthOfTextAtSize(certDateText, fontsize);
        
            yPosition = drawText(certNameText, 50, yPosition, fontsize, timesRomanFont);
            yPosition = drawText(certDateText, width - certDateTextWidth - 50, yPosition, fontsize);
        
            yPosition -= 20;
        });

  }
}
  const generateTemplate2 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const timesRomanItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesRomanBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    let page = createPage();
    let { width, height } = page.getSize();

    const drawText_center = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const textWidth = font.widthOfTextAtSize(textToDraw, size);
      const xPosition = (595 - textWidth) / 2; 

      if (y < 50) {
        page = createPage();
        y = height - 50;
      }

      page.drawText(text, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';

      if (y < 50) {
        page = createPage();
        y = height - 50;
      }
      page.drawText(textToDraw, { x, y, size, font, color });
      return y;
    };

      const drawSectionHeader = (text, y) => {
          y = drawText(text, 50, y, fontsize, timesRomanBoldFont, rgb(0, 0, 0));
          return y - 20;
      };

      

        const drawAlignedText = (leftText, rightText, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
            const leftTextWidth = leftText ? font.widthOfTextAtSize(leftText, size) : 0;
            const rightTextWidth = rightText ? font.widthOfTextAtSize(rightText, size) : 0;
          
            if (y < 50) {
              page = createPage();
              y = height - 50;
            }
          
            if (leftText) {
              page.drawText(leftText, { x: 50, y, size, font, color });
            }
            
            if (rightText) {
              page.drawText(rightText, { x: width - rightTextWidth - 50, y, size, font, color });
            }
          
            return y;
        };

        const drawBulletedText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
          const bullet = '• ';
          const bulletWidth = font.widthOfTextAtSize(bullet, size);
          const indent = x + bulletWidth; // Position for the subsequent lines
          const words = text.split(' ');
          let line = '';
          let firstLine = true;

          words.forEach(word => {
            const testLine = line + word + ' ';
            const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

            if (testLineWidth < maxWidth) {
              line = testLine;
            } else {
              y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
              y -= size + 4;
              line = word + ' ';
              firstLine = false;
            }
          });

          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          return y - size - 4;
        };

        const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
          const words = text.split(' ');
          let line = '';
          let lines = [];
          words.forEach(word => {
            if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
              line += word + ' ';
            } else {
              lines.push(line);
              line = word + ' ';
            }
          });
          lines.push(line);
          lines.forEach((line, index) => {
            y = drawText(line, x, y, size, font, rgb(0, 0, 0));
            y -= size + 4;
          });
          return y;
        };

        const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            const words = text.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
                lines.push(line);
                line = word + ' ';
              } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
                line += word + ' ';
              } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
                line += word + ' ';
              } else {
                lines.push(line);
                line = word + ' ';
              }
            });
            lines.push(line);
            lines.forEach((line, index) => {
              if (index === 0) {
                y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              } else {
                y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
              }
              y -= size + 4;
            });
            return y;
          };

          const drawTextWithWrappingSkillsCategoryKey = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            const words = text.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
                lines.push(line);
                line = word + ' ';
              } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
                line += word + ' ';
              } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
                line += word + ' ';
              } else {
                lines.push(line);
                line = word + ' ';
              }
            });
            lines.push(line);
            lines.forEach((line, index) => {
              if (index === 0) {
                y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              } else {
                y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
              }
            });
            return y;
          };

        // Header
        // Construct the header information dynamically
        const parts = [user_location, phone_number, email, personal_website].filter(item => item);
        const headerInfo = parts.join(' | ');

        let yPosition = height - 40;
        yPosition = drawText_center(name || '', 50, yPosition, name_fontsize, timesRomanBoldFont);
        // Only draw the header info if it's not empty
        if (headerInfo) {
          yPosition = drawText_center(headerInfo, 50, yPosition - 20, fontsize);
        }
       

        // Summary
        if (summary) {
          yPosition -= 30;
          yPosition = drawSectionHeader('SUMMARY', yPosition);

          yPosition += 15;

          // Draw a line 
          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          
          yPosition = drawTextWithWrapping(summary, 50, yPosition-13, fontsize, timesRomanFont);
          // Add extra space after the summary
          
          yPosition -= 4;
        } else {
          yPosition -= 30;
        }



        // Education
        if (education_list && education_list.length > 0) {
          yPosition = drawSectionHeader('EDUCATION', yPosition);
          
          yPosition += 15; 

        // Draw a line after EDUCATION
          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 3; 
        

          education_list.forEach((edu, index) => {
            const universityText=`${edu.university}`
            yPosition = drawText(universityText, 50, yPosition - 10, fontsize, timesRomanBoldFont); // University and location left-aligned, bold, and on its own line
            yPosition -= 4;
            const durationText = `${edu.university_start_month} ${edu.university_start_year} - ${edu.graduation_month} ${edu.graduation_year}`;
            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            yPosition = drawAlignedText(degree_major_text, durationText, yPosition - 10, fontsize, timesRomanFont);
            if (edu.GPA) {
              yPosition -= 4;
              const gpaText = `GPA: ${edu.GPA}`;
              yPosition = drawText(gpaText, 50, yPosition - 10, fontsize, timesRomanFont); // Adjust x position as needed
            }
            // Check if the current element is the last one
            if (index === education_list.length - 1) {
                yPosition -= 20;
              } else {
                yPosition -= 10;
              }
          });
         
        }

        // Work Experience
        if (revisedWorkExp && revisedWorkExp.length > 0) {
          yPosition = drawSectionHeader('WORK EXPERIENCE', yPosition);

          yPosition += 15; 

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 3; 
        
          revisedWorkExp.forEach((exp, index) => {
            yPosition = drawAlignedText(exp.Experience.Company, exp.Experience.Location, yPosition - 10, fontsize, timesRomanBoldFont);
            yPosition -= 4;
            // yPosition = drawAlignedText(exp.Experience.Title, exp.Experience.Duration, yPosition - 10, 12, timesRomanFont);
            yPosition = drawAlignedText(exp.Experience.Title, "", yPosition - 10, fontsize, timesRomanBoldItalicFont);
            yPosition = drawAlignedText("", exp.Experience.Duration, yPosition, fontsize, timesRomanItalicFont);
        
            yPosition -= 15;
           
            exp.Experience.Responsibilities.forEach(resp => {
              yPosition = drawBulletedText(resp, 50, yPosition);
            });
            // Check if the current element is the last one
            if (index === revisedWorkExp.length - 1) {
                yPosition -= 5;
            }
          });
        }
      
        // Project Experience
        if (revisedProjExp && revisedProjExp.length > 0) {
          yPosition = drawSectionHeader('PROJECT EXPERIENCE', yPosition);
          yPosition += 15;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 3; 
          revisedProjExp.forEach((exp, index) => {
          
            if (exp.Experience) { // Ensure 'Experience' object exists

              yPosition = drawAlignedText(exp.Experience.Company, exp.Experience.Location, yPosition - 10, fontsize, timesRomanBoldFont);
              yPosition -= 4;
        
              yPosition = drawAlignedText(exp.Experience.Title, "", yPosition - 10, fontsize, timesRomanBoldItalicFont);
              yPosition = drawAlignedText("", exp.Experience.Duration, yPosition, fontsize, timesRomanItalicFont);

              yPosition -= 15;

              exp.Experience.Responsibilities.forEach(resp => {
                yPosition = drawBulletedText(resp, 50, yPosition);
              });
              // Check if the current element is the last one
              if (index === revisedProjExp.length - 1) {
                yPosition -= 5;
              }
            }
          });
        }
        // Skills
        if (skills && skills.length > 0) {
          yPosition = drawSectionHeader('SKILLS', yPosition);
          yPosition += 15;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 13;
          if (isSkillsCategorized) {
            let lastValidIndex = -1;
            // First, identify the last valid index
            Object.keys(categorizedSkills).forEach((key, index) => {
                if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                    lastValidIndex = index;
                }
            });

            Object.keys(categorizedSkills).forEach((key, index) => {
                // Check if the value is an empty list or null
                if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                    return; // Skip to the next iteration
                }

                let skillsText = categorizedSkills[key].join(', ');
                keyText = key + ': ';
                yPosition = drawTextWithWrappingSkillsCategoryKey(keyText, 50, yPosition, fontsize, timesRomanBoldFont);
                yPosition = drawTextWithWrappingSkillsCategory(skillsText, 50 + timesRomanBoldFont.widthOfTextAtSize(keyText, fontsize), yPosition, fontsize, timesRomanFont);

                // Check if the current key is the last valid element in categorizedSkills
                if (index === lastValidIndex) {
                    yPosition -= 5;
                }
            });
            } else {
                const skillsText = skills.join(', ');
                yPosition = drawTextWithWrapping(skillsText, 50, yPosition, fontsize, timesRomanFont);
                yPosition -= 5;
            }
        }

        // Certificates
        if (certificates && certificates.length > 0) {
          yPosition = drawSectionHeader('CERTIFICATES', yPosition);
          yPosition += 13;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 13;

          certificates.forEach(cert => {
            const certNameText = '• ' + (cert.name || '');
            const certDateText = cert.achieved_date || '';
            const certDateTextWidth = timesRomanFont.widthOfTextAtSize(certDateText, fontsize);
            yPosition = drawText(certNameText, 50, yPosition, fontsize, timesRomanFont);
            yPosition = drawText(certDateText, width - certDateTextWidth - 50, yPosition, fontsize);
            yPosition -= 20;
          });
        }
  };

  const generateTemplate3 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const timesRomanItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesRomanBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    const getPage = (pageIndex) => {
        if (pageIndex < pdfDoc.getPageCount()) {
            return pdfDoc.getPage(pageIndex); // Access the existing page
        } else {
            return createPage(); // Create a new page if it doesn't exist
        }
    };

    let currentPageIndex = 0;
    let page = getPage(currentPageIndex); // Start with the first page
    let { width, height } = page.getSize();

    const drawText_left = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const textWidth = font.widthOfTextAtSize(textToDraw, size);
      const xPosition = 50;

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }

      page.drawText(text, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText_right = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
        // Ensure text is not null or undefined
        const textToDraw = text || '';
        const textWidth = font.widthOfTextAtSize(textToDraw, size);
        const xPosition = 397;
  
        if (y < 50) {
          // page = createPage();
          currentPageIndex += 1;
          page = getPage(currentPageIndex);
          y = height - 50;
        }
  
        page.drawText(text, { x: xPosition, y, size, font, color });
        return y;
      };

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }
      page.drawText(textToDraw, { x, y, size, font, color });
      return y;
    };

      const drawSectionHeader = (text, y) => {
          y = drawText(text, 50, y, fontsize, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));
          return y - 20;
      };

      const drawSectionHeader_right = (text, y) => {
        y = drawText_right(text, 50, y, fontsize, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));
        return y - 20;
      };
      

        const drawBulletedText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = 330) => {
          const bullet = '• ';
          const bulletWidth = font.widthOfTextAtSize(bullet, size);
          const indent = x + bulletWidth; // Position for the subsequent lines
          const words = text.split(' ');
          let line = '';
          let firstLine = true;

          words.forEach(word => {
            const testLine = line + word + ' ';
            const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

            if (testLineWidth < maxWidth) {
              line = testLine;
            } else {
              y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
              y -= size + 4;
              line = word + ' ';
              firstLine = false;
            }
          });

          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          return y - size - 4;
        };

        const drawBulletedText_right = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 50) => {
            const bullet = '• ';
            const bulletWidth = font.widthOfTextAtSize(bullet, size);
            const indent = x + bulletWidth; // Position for the subsequent lines
            const words = text.split(' ');
            let line = '';
            let firstLine = true;
  
            words.forEach(word => {
              const testLine = line + word + ' ';
              const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);
  
              if (testLineWidth < maxWidth - x) {
                line = testLine;
              } else {
                y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
                y -= size + 4;
                line = word + ' ';
                firstLine = false;
              }
            });
  
            y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
            return y - size - 4;
          };

        const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = 330) => {
          const words = text.split(' ');
          let line = '';
          let lines = [];
          words.forEach(word => {
            if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
              line += word + ' ';
            } else {
              lines.push(line);
              line = word + ' ';
            }
          });
          lines.push(line);
          lines.forEach((line, index) => {
            y = drawText(line, x, y, size, font, rgb(0, 0, 0));
            y -= size + 4;
          });
          return y;
        };

        
          const drawTextWithWrapping_right = (text, x = 397, y, size = fontsize, font = timesRomanFont, maxWidth = width - 70) => {
            const words = text.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              // Check if the current line plus the new word is within the maxWidth
              if (font.widthOfTextAtSize(line + word, size) < maxWidth - x) {
                line += word + ' ';
              } else {
                // If the line exceeds maxWidth, push it to lines array
                lines.push(line.trim());
                line = word + ' ';
              }
            });
            // Push the last line
            lines.push(line.trim());
          
            // Draw each line at the specified x and y, adjusting y for each line
            lines.forEach((line, index) => {
              y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              y -= size + 4; // Adjust spacing between lines
            });
          
            return y;
          };

          const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            const words = text.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
                lines.push(line);
                line = word + ' ';
              } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
                line += word + ' ';
              } else if (x + font.widthOfTextAtSize(line + word, size) - 50 < maxWidth) {
                line += word + ' ';
              } else {
                lines.push(line);
                line = word + ' ';
              }
            });
            lines.push(line);
            lines.forEach((line, index) => {
              if (index === 0) {
                y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              } else {
                y = drawText(line, 397, y, size, font, rgb(0, 0, 0));
              }
              y -= size + 4;
            });
            return y;
          };

        // Header
        // Construct the header information dynamically
        const parts = [personal_website, email, phone_number].filter(item => item);
        //for left column
        let yPosition = height - 40;
        //for right column
        let yPosition_right_column = height - 40;
        // Only draw the header info if it's not empty
        if (parts) {
            parts.map((element, index) => {
                yPosition_right_column = drawText_right(element, 50, yPosition_right_column, fontsize);
                yPosition_right_column = yPosition_right_column - 15;
            });
        }
        if (yPosition_right_column > 757) {
            yPosition_right_column = 757;
        }
        yPosition_right_column -= 10;
       

        // Education
        if (education_list && education_list.length > 0) {
          yPosition_right_column = drawSectionHeader_right('EDUCATION', yPosition_right_column);
          
          education_list.forEach((edu, index) => {
            const universityText = `${edu.university}`;
            yPosition_right_column = drawTextWithWrapping_right(universityText, 397, yPosition_right_column, fontsize, timesRomanBoldFont); // University and location left-aligned, bold, and on its own line
            yPosition_right_column -= 1;
            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            const durationText = `${edu.university_start_month} ${edu.university_start_year} - ${edu.graduation_month} ${edu.graduation_year}`;
            yPosition_right_column = drawTextWithWrapping_right(degree_major_text, 397, yPosition_right_column, fontsize, timesRomanFont);
            yPosition_right_column -= 1;
            if (edu.GPA) {
                const gpaText = `GPA: ${edu.GPA}`;
                yPosition_right_column = drawTextWithWrapping_right(gpaText, 397, yPosition_right_column, fontsize, timesRomanFont); // Adjust x position as needed
                yPosition_right_column -= 1;
            }
            yPosition_right_column = drawText_right(durationText, 50, yPosition_right_column, 10, timesRomanFont, rgb(0.431, 0.416, 0.42));
          
            // Check if the current element is the last one
            if (index === education_list.length - 1) {
                yPosition_right_column -= 20;
            } else {
                yPosition_right_column -= 20;
            }
          });
        }

        if (skills && skills.length > 0) {
            yPosition_right_column -= 25;
            yPosition_right_column = drawSectionHeader_right('SKILLS', yPosition_right_column);
            if (isSkillsCategorized) {
              let lastValidIndex = -1;
              // First, identify the last valid index
              Object.keys(categorizedSkills).forEach((key, index) => {
                  if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                      lastValidIndex = index;
                  }
              });
  
              Object.keys(categorizedSkills).forEach((key, index) => {
                  // Check if the value is an empty list or null
                  if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                      return; // Skip to the next iteration
                  }
                  let skillsText = categorizedSkills[key].join(', ');
                  yPosition_right_column = drawTextWithWrappingSkillsCategory(key, 397, yPosition_right_column, fontsize, timesRomanBoldFont);
                  yPosition_right_column -= 5;
                  yPosition_right_column = drawTextWithWrappingSkillsCategory(skillsText, 397, yPosition_right_column, fontsize, timesRomanFont);
  
                  // Check if the current key is the last valid element in categorizedSkills
                  if (index === lastValidIndex) {
                    yPosition_right_column -= 5;
                  } else {
                    yPosition_right_column -= 10;
                  }
              });
              } else {
                  const skillsText = skills.join(', ');
                  yPosition_right_column = drawTextWithWrapping_right(skillsText, 397, yPosition_right_column, fontsize, timesRomanFont);
                  yPosition_right_column -= 5;
              }
          }

        // Certificates
        if (certificates && certificates.length > 0) {
            yPosition_right_column -= 25;
            yPosition_right_column = drawSectionHeader_right('CERTIFICATES', yPosition_right_column);
            certificates.forEach(cert => {
              const certNameText = (cert.name || '');
              const certDateText = cert.achieved_date || '';
              yPosition_right_column = drawBulletedText_right(certNameText, 397, yPosition_right_column);
              yPosition_right_column = drawText_right(certDateText, 410, yPosition_right_column, 10, timesRomanFont, rgb(0.431, 0.416, 0.42))
              yPosition_right_column -= 20;
            });
        }


        currentPageIndex = 0; // Start again with the first page
        page = getPage(currentPageIndex); // Access the first page

        yPosition -= 15;
        yPosition = drawText_left(name || '', 50, yPosition, name_fontsize_template_3, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));

        // Summary
        if (summary) {
            yPosition -= 40;
            yPosition = drawSectionHeader('SUMMARY', yPosition);
            yPosition = drawTextWithWrapping(summary, 50, yPosition, fontsize, timesRomanFont);
            yPosition -= 20;
            } else {
            yPosition -= 40;
        }

        // Work Experience
        if (revisedWorkExp && revisedWorkExp.length > 0) {
          yPosition = drawSectionHeader('WORK EXPERIENCE', yPosition);
        
          revisedWorkExp.forEach((exp, index) => {
            const exp_title = `${exp.Experience.Title} at ${exp.Experience.Company}`;
            yPosition = drawText_left(exp_title, 50, yPosition, fontsize, timesRomanBoldFont);
            yPosition -= 12;
            const location_text = `${exp.Experience.Location} | ${exp.Experience.Duration}`;
            yPosition = drawText_left(location_text, 50, yPosition, fontsize, timesRomanBoldFont);
        
            yPosition -= 15;
           
            exp.Experience.Responsibilities.forEach(resp => {
              yPosition = drawBulletedText(resp, 50, yPosition);
            });
            // Check if the current element is the last one
            if (index === revisedWorkExp.length - 1) {
                yPosition -= 5;
            } else {
                yPosition -= 5;
            }
          });
        }
      
        // Project Experience
        if (revisedProjExp && revisedProjExp.length > 0) {
          yPosition -= 15;
          yPosition = drawSectionHeader('PROJECT EXPERIENCE', yPosition);

          revisedProjExp.forEach((exp, index) => {
            if (exp.Experience) { // Ensure 'Experience' object exists
              const exp_title = `${exp.Experience.Title} at ${exp.Experience.Company}`;
              yPosition = drawText_left(exp_title, 50, yPosition, fontsize, timesRomanBoldFont);
              yPosition -= 12;
              const location_text = `${exp.Experience.Company}, ${exp.Experience.Location} | ${exp.Experience.Duration}`;
              yPosition = drawText_left(location_text, 50, yPosition, fontsize, timesRomanBoldFont);

              yPosition -= 15;

              exp.Experience.Responsibilities.forEach(resp => {
                yPosition = drawBulletedText(resp, 50, yPosition);
              });
              // Check if the current element is the last one
              if (index === revisedProjExp.length - 1) {
                yPosition -= 5;
              } else {
                yPosition -= 5;
              }
            }
          });
        }
  };


  const generatePdf = async () => {
    const pdfDoc = await PDFDocument.create();
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesRomanBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    if (selectedTemplate === 0) {
      await generateTemplate1(pdfDoc, timesRomanFont, timesRomanBoldFont);
    } else if (selectedTemplate === 1) {
      await generateTemplate2(pdfDoc, timesRomanFont, timesRomanBoldFont);
    } else if (selectedTemplate === 2) {
      await generateTemplate3(pdfDoc, timesRomanFont, timesRomanBoldFont);
    }

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);
    setPdfBlob(pdfBlob);
  };

  useEffect(() => {
    console.log('Selected template changed:', selectedTemplate); 
    generatePdf();
  }, [selectedTemplate]);

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = 'resume.pdf';
    link.click();
  };

  const handleTemplateClick = (index) => {
    setSelectedTemplate(index);
  };

  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/fetch_resume_history', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      // handle the response data
      //console.log(data);
      //update setapiresponse that is used in result.js
      setApiResponse(data);
      //stop loading and redirect to /result
      setLoading(false);
      //history.push("/result");
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);
      alert(error.message);  // Displaying the error message to the user
    });
  }

  if (loading) {    
    return <Loading_mainpage />;
  } else if (apiResponse) {
    
    navigate('/Profile', { state: { data: apiResponse } });
  }

  return (
    isAuthenticated ? (
      <div className="pdf-generator">
        <Helmet>
          <title>Preview - ResuMaster</title>
          <meta property="og:title" content="Preview - ResuMaster" />
          <script src="//embed.typeform.com/next/embed.js"></script>
        </Helmet>
        {isAuthenticated ? (
          <Navigation_loggedin className="navigation-2202" />
        ) : (
          <Navigation className="navigation-2202" />
        )}
        <div className="pdf-generator-main-content">
          <div className="preview-section">
            <div className="back-to-edit" onClick={sendToServer}>
                <img className="preview-back-button-img" src='/img/chevron-left.png' alt="Back Button" />
                <div className="preview-back-button-text">Back to Profile</div>
            </div>
            {pdfUrl ? (
              <iframe
                src={pdfUrl}
                className="preview-iframe"
                frameBorder="0"
              />
            ) : (
              <p>Loading PDF...</p>
            )}
            
              <div
                data-tf-widget="MUjQuSF6"
                data-tf-opacity="100"
                data-tf-iframe-props="title=Resumaster.AI feedbacks"
                data-tf-transitive-search-params
                data-tf-medium="snippet"
                style={{ width: '100%', height: '400px', borderRadius: 10 }}
              />
            
          </div>
          <div className="templates-section">
            <h2 className="templates-header">Templates</h2>
            <div className="template-list">
              {[1,0,2].map((element, index) => (
                <div
                  key={element}
                  className={`template-thumbnail ${selectedTemplate === element ? 'selected' : ''}`}
                  onClick={() => handleTemplateClick(element)}
                >
                  <img src={`/img/template_${element + 1}.png`} alt={`Template ${element + 1}`} />
                  <p>{element === 0 ? 'Modern' : element === 1 ? 'Classic' : 'Innovative'}</p>
                </div>
              ))}
            </div>
            <div className="pdf-generator-button-container">
              <button onClick={downloadPdf} className="download-button">
                Export as pdf
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>Please log in first</div>
    )
  );  
} catch (error) {
    handleError(error);
    return null; // Exit the component rendering if there is an error
}
};
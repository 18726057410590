import React, { useState, useRef, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { FrameWrapper } from "../../components/FrameWrapper";
import "./style.css";
import { Link } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { Loading_partial } from '../Loading/Loading_partial';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------


export const Result = () => {
  //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  //---------------------------------auhentication code----------------------------------------
  // get the data fetched from the upload page
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data.rendering_data;
  const store_data = location.state?.data.store_data;
  const job_description = location.state?.job_description;
  const company_analysis = location.state?.company_analysis;
  // ======get the parsed data from user's resume: you can find the personal info here======
  const original_data = location.state?.original_data;
  console.log(original_data)
  // ======get the parsed data from user's resume: you can find the personal info here======
  const [loading, setLoading] = useState(false); // state for loading
  const [apiResponse, setApiResponse] = useState(null);

  const [generatedSummary, setGeneratedSummary] = useState(null);
  const [showSummary, setShowSummary] = useState(false);

  const [showRevised, setShowRevised] = useState({});
  useEffect(() => {
    if (apiResponse) {
      const summary = apiResponse.executive_summary;
      setGeneratedSummary(summary);
      setShowSummary(true);
    }
  }, [apiResponse]); // This effect depends on `apiResponse`


  //used for testing only
  //const data = require('./data.json');
  // console.log("Debug data location:", location.data);


  // Combine work and project experiences
  // const combinedOriginalExperience = originalWorkExperience.concat(originalProjectExperience);
  // keywords in jobdescription
  // const jd_keywords = data.jd_key_words;

  
  //create a ref
  const textAreaRef = useRef(null);

  const handleToggleRevised = (prefix, index) => {
    const uniqueKey = `${prefix}-${index}`;
    setShowRevised({ ...showRevised, [uniqueKey]: !showRevised[uniqueKey] });
  };

  const handleCopyToClipboard = (text) => {
    textAreaRef.current.value = text;
    textAreaRef.current.select();
    document.execCommand('copy');
  };

//   if (loading) {
//     // return (
//     //     <div className="loading-screen">
//     //         <BeatLoader color={"#123abc"} loading={loading} size={40} />
//     //         <p>Please wait approximately 90 seconds for the suggestions</p>
//     //     </div>
//     // );
  
//      return <Loading />
  
//    } 
   


  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return;
      }
    //---------------------------------auhentication code----------------------------------------
    
    // create a FormData object
    const formData = new FormData();
    // append the file and the job description
    formData.append('job_description', job_description);
    formData.append('company_description', company_analysis);
    
    // loop through resume data
    const resume = [];
    for (let i = 0; i < data.length - 1; i++) {
      const myObject = {};
      myObject["title"] = data[i].Experience.Title;
      myObject["duration"] = data[i].Experience.Duration;
      myObject["company"] = data[i].Experience.Company;
      myObject["location"] = data[i].Experience.Location;
      myObject["content"] = data[i].Experience.Responsibilities;
      resume.push(myObject);
    }
  
    formData.append('resume', resume);
    
    
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/executive_summary', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
      body: formData
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      //console.log(data)
      //update setapiresponse that is used in result.js
      setApiResponse(data);
      //console.log(apiResponse);
      //stop loading and redirect to /result
      setLoading(false);
      //history.push("/result");
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);
  
      alert(error.message);  // Displaying the error message to the user
    });
  }

  const sendToServer_db = async (navigateTo) => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return;
      }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // create a FormData object
    const formData = new FormData();
    // append the file and the job description
    formData.append('company_analysis', company_analysis);
    formData.append('executive_summary', generatedSummary === null ? "null" : generatedSummary);
    formData.append('db_json_store', JSON.stringify(store_data));
    
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/save_revision_history', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
      body: formData
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      setLoading(false);
      navigate(navigateTo);
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);
  
      alert(error.message);  // Displaying the error message to the user
    });
  }


  
  return (
    isAuthenticated ? (
    <div className="results-page">
      <Helmet>
        <title>Results - ResuMaster</title>
        <meta property="og:title" content="Results - ResuMaster" />
        <body className="result_page" />
         <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="div">
        <div className="frame-2">
          <div className="frame-3">
            <div className="title">Result</div>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
            <div className="credits-used">-100 ResuCredits</div>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
          </div>
          <div className="frame-4">
            <p className="p">Key words from job description</p>
            <div className="frame-5">
              {data[data.length - 1]["keywords_list"].map((keyword, index) => (
                <FrameWrapper key={index} className="frame-2213" text={keyword} />
              ))}
            </div>
          </div>
          <div className="frame-4">
            <p className="p">Executive Summary</p>
            <div className="summary-container">
                <div className="centered-container">
                {loading ? (
                    <Loading_partial />
                ) : showSummary ? (
                    <p className="executive_summary">{generatedSummary}</p>
                ) : (
                    <Button onClick={sendToServer} text="Generate an Executive Summary based on Revision"/>
                )}
                </div>
              </div>
          </div>
        </div>
        <img className="line" alt="Line" src="/img/line-11.png" />
      </div>
      <div className="frame-6">
        <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }}></textarea>
        {data.slice(0, -1).map((experience, index) => (
          <div className="frame-4">
            <div className="experience">
              <div className="current">
                <div className="frame-7">
                  <div className="text-wrapper-2">{experience.Experience.Title} at {experience.Experience.Company}</div>
                  <p className="may-aug">
                    {experience.Experience.Duration}
                    <br />
                    {experience.Experience.Location}
                  </p>
                </div>
                {/* <p className="lorem-ipsum-dolor">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                  dolore magna aliqua. Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                                  <br />
                                  Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                  veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.usmod tempor
                                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                  <br />
                                  Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                </p> */}
                <div className="lorem-ipsum-dolor">
                  <ul>
                    {experience.original_description.map((summary, idx) => (
                      <li key={idx}>{summary}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="revised">
                <div className="expand" onClick={() => handleToggleRevised('work', index)}>
                  <div className="text-wrapper-3"> {showRevised[`work-${index}`] ? "Hide Revised Content" : "View Revised Content"} </div>
                  {showRevised[`work-${index}`] ? <img className="img" alt="Chevron up" src="/img/chevron-up.png" /> : <img className="img" alt="Chevron down" src="/img/chevron-down.png" />}

                </div>

                {showRevised[`work-${index}`] && (
                  <>
                    <img className="line-2" alt="Line" src="/img/line-10.png" />
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="frame-7">
                          <div className="text-wrapper-2">{experience.Experience.Title} at {experience.Experience.Company}</div>
                          <p className="may-aug">
                            {experience.Experience.Duration}
                            <br />
                            {experience.Experience.Location}
                          </p>
                        </div>
                        {/* <img className="img" alt="Copy" src="/img/copy.png" /> */}
                        <img className="img"
                          alt="Copy"
                          src="/img/copy.png"
                          onClick={() => handleCopyToClipboard(experience.Experience.Responsibilities.join('\n'))} // join array items with new lines
                        />
                      </div>
                      {/* <p className="lorem-ipsum-dolor">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                                    <br />
                                    Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.usmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    <br />
                                    Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                  </p> */}
                      <div className="lorem-ipsum-dolor">
                        <ul>
                          {experience.Experience.Responsibilities.map((revisedSummary, idx) => (
                            <li key={idx}>{revisedSummary}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

              </div>
            </div>
          </div>
        ))}

        
      </div>
      <div className="feedback-box-resultpage">
        <div
          data-tf-widget="MUjQuSF6"
          data-tf-opacity="100"
          data-tf-iframe-props="title=Resumaster.AI feedbacks"
          data-tf-transitive-search-params
          data-tf-medium="snippet"
          style={{ width: '100%', height: '700px', borderRadius: 10}}
        />
      </div>
      <div className="frame-10">
        <Button className="button-secondary" text="Back" onClick={() => sendToServer_db('/upload')} />
      
        <Button className="result-button" text="Finish" onClick={() => sendToServer_db('/')} />
      </div>
    </div>
  ) : (
    <div>Please log in first</div>
  ))
};

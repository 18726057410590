import React, { useState, useRef, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { FrameWrapper } from "../../components/FrameWrapper";
import "./style.css";
import { Link } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom'
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Loading_mainpage } from '../../screens/Loading';
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

// //executive summary
// export const Summary = () => {
//   const [showSummary, setShowSummary] = useState(false);
//   const [generatedSummary, setGeneratedSummary] = useState("");

//   const generateSummary = () => {
//     const summary = "This is an executive summary based on your resume, This is an executive summary based on your resume This is an executive summary based on your resume";
//     setGeneratedSummary(summary);
//     setShowSummary(true);
//   }

//   return(
//     <div>
//     {showSummary ? (
//       <p>{generatedSummary}</p>
//     ) : (
//       <Link className="summary-link" onClick={generateSummary}>Click to generate an executive summary</Link>
//     )}
//     </div>
//   )
// }

export const Result_history = () => {
  //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  //---------------------------------auhentication code----------------------------------------
  const [loading, setLoading] = useState(false); // state for loading
  const [apiResponse, setApiResponse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get the data fetched from the upload page
  const location = useLocation();
  const data_received = location.state?.data;
  //console.log("from result history page: " + data_received)
  const index_history = location.state?.index;
  const data = JSON.parse(data_received.results[index_history]);
  const executive_summary = data_received.executive_summary[index_history];

  const [showRevised, setShowRevised] = useState({});
  //create a ref
  const textAreaRef = useRef(null);

  const handleToggleRevised = (prefix, index) => {
    const uniqueKey = `${prefix}-${index}`;
    setShowRevised({ ...showRevised, [uniqueKey]: !showRevised[uniqueKey] });
  };

  function handleBack() {
    navigate(-1); // Navigates to the previous page in the history stack
  }

  const handleCopyToClipboard = (text) => {
    textAreaRef.current.value = text;
    textAreaRef.current.select();
    document.execCommand('copy');
  };

  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/fetch_resume_history', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      // handle the response data
      //console.log(data);
      //update setapiresponse that is used in result.js
      setApiResponse(data);
      //stop loading and redirect to /result
      setLoading(false);
      //history.push("/result");
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);
      alert(error.message);  // Displaying the error message to the user
    });
  }

  if (!isAuthenticated) {
    return <div>Please log in to view your revision history.</div>;
  }

  if (loading) {    
    return <Loading_mainpage />;
  } else if (apiResponse) {
    
    navigate('/Profile', { state: { data: apiResponse } });
  }


  return (
    <div className="results-page">
      <Helmet>
        <title>Results - ResuMaster</title>
        <meta property="og:title" content="Results - ResuMaster" />
        <body className="result_page" />
         <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="div">
        <div className="frame-2">
          <div className="frame-3">
            <div className="title">Result</div>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
            <div className="credits-used">-100 ResuCredits</div>
            {/*============================== temporary comment out for deployment 06/09===================================*/}
          </div>
          <div className="frame-4">
            <p className="p">Key words from job description</p>
            <div className="frame-5">
              {data[data.length - 1]["keywords_list"].map((keyword, index) => (
                <FrameWrapper key={index} className="frame-2213" text={keyword} />
              ))}
            </div>
          </div>
          <div className="frame-4">
            <p className="p">Executive Summary</p>
            <div className="summary-container">
                <div className="centered-container">
                
                
                {executive_summary !== "null" ? (
                    <p className="executive_summary">{executive_summary}</p>
                    ) : (
                    <p className="executive_summary">No Executive Summary Available</p>
                )}
                </div>
              </div>
          </div>
        </div>
        <img className="line" alt="Line" src="/img/line-11.png" />
      </div>
      <div className="frame-6">
        <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }}></textarea>
        {data.slice(0, -1).map((experience, index) => (
          <div className="frame-4">
            <div className="experience">
              <div className="current">
                <div className="frame-7">
                  <div className="text-wrapper-2">{experience.Experience.Title} at {experience.Experience.Company}</div>
                  <p className="may-aug">
                    {experience.Experience.Duration}
                    <br />
                    {experience.Experience.Location}
                  </p>
                </div>
                {/* <p className="lorem-ipsum-dolor">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                  dolore magna aliqua. Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                                  <br />
                                  Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                  veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.usmod tempor
                                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                  <br />
                                  Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                </p> */}
                <div className="lorem-ipsum-dolor">
                  <ul>
                    {experience.original_description.map((summary, idx) => (
                      <li key={idx}>{summary}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="revised">
                <div className="expand" onClick={() => handleToggleRevised('work', index)}>
                  <div className="text-wrapper-3"> {showRevised[`work-${index}`] ? "Hide Revised Content" : "View Revised Content"} </div>
                  {showRevised[`work-${index}`] ? <img className="img" alt="Chevron up" src="/img/chevron-up.png" /> : <img className="img" alt="Chevron down" src="/img/chevron-down.png" />}

                </div>

                {showRevised[`work-${index}`] && (
                  <>
                    <img className="line-2" alt="Line" src="/img/line-10.png" />
                    <div className="frame-8">
                      <div className="frame-9">
                        <div className="frame-7">
                          <div className="text-wrapper-2">{experience.Experience.Title} at {experience.Experience.Company}</div>
                          <p className="may-aug">
                            {experience.Experience.Duration}
                            <br />
                            {experience.Experience.Location}
                          </p>
                        </div>
                        {/* <img className="img" alt="Copy" src="/img/copy.png" /> */}
                        <img className="img"
                          alt="Copy"
                          src="/img/copy.png"
                          onClick={() => handleCopyToClipboard(experience.Experience.Responsibilities.join('\n'))} // join array items with new lines
                        />
                      </div>
                      {/* <p className="lorem-ipsum-dolor">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                                    <br />
                                    Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.usmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                    <br />
                                    Ut enim ad minim veniam.usmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                  </p> */}
                      <div className="lorem-ipsum-dolor">
                        <ul>
                          {experience.Experience.Responsibilities.map((revisedSummary, idx) => (
                            <li key={idx}>{revisedSummary}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

              </div>
            </div>
          </div>
        ))}

        
      </div>
      <div className="frame-10">
        <Button className="button-secondary" onClick={handleBack} text="Back" />

        <Button className="result-button" onClick={sendToServer} text="Finish" />
      </div>
    </div>
  );
};

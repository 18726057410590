import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Result } from "./screens/Results";
import  ResumeResults  from'./screens/ResumeResults/ResumeResults';
import { HomeNew } from "./screens/HomeNew";
import { Upload } from "./screens/upload";
import { Loading } from "./screens/Loading";
import { Contact } from "./screens/Contact";
import { GetStarted } from "./screens/GetStarted";
import { ClInfo } from "./screens/ClInfo/ClInfo";
import { ClResults } from "./screens/ClResults";
import { Upload_cl } from "./screens/upload";
import { SuggestedEdits } from "./screens/SuggestedEdits";
import { SuggestedEdits_hostory } from "./screens/Suggestededits_history";
import { Result_history } from "./screens/Results_history"
import { Upload_text } from "./screens/upload";
import { Profile } from "./screens/Profile";
import { Plan } from "./screens/Plan";
import { PaymentPage } from "./screens/Payment"
import { PaymentSuccess } from "./screens/Success"
import { Coverletter_history } from "./screens/Coverletter_history"
import { UnverifiedEmail } from "./screens/Email_verification"
import { New_suggested_edits } from "./screens/New_suggested_edits"
import { Term_of_service } from "./screens/Term_of_service"
import { Privacy_policy } from "./screens/Privacy_policy"
import { ResuBot } from "./screens/Resubot"
import { PdfGenerator } from "./screens/PdfGenerator"
import { RevisionHistoryPDF } from "./screens/RevisionHistoryPDF"
import { Loading_upload } from "./screens/Loading"
import 'bootstrap/dist/css/bootstrap.min.css';

const router = createBrowserRouter([
  {
    path: "/results",
    element: <Result />,
  },
  {
    path: "/resume-preview",
    element: <PdfGenerator />,
  },
  {
    path: "/ResumeResults",
    element: <ResumeResults />,
  },
  {
    path: "/*",
    element: <HomeNew />,
  },
  {
    path: "/upload",
    element: <Upload />,
  },
  {
    path: "/Loading",
    element: <Loading />,
  },
  {
    path: "/Loading_upload",
    element: <Loading_upload />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/GetStarted",
    element: <GetStarted />,
  },
  {
    path: "/ClInfo",
    element: <ClInfo />,
  },
  {
    path: "/ClResults",
    element: <ClResults />,
  },
  {
    path: "/coverletter_upload",
    element: <Upload_cl />
  },
  {
    path: "/new_resume_revision",
    element: <New_suggested_edits />
  },
  {
    path: "/upload_resume_text",
    element: <Upload_text />
  },
  {
    path: "/Profile",
    element: <Profile />
  },
  {
    path: "/new_resume_revision_history",
    element: <RevisionHistoryPDF />
  },
  {
    path: "/new_resume_result_history",
    element: <Result_history />
  },
/*============================== temporary comment out for deployment 06/09===================================*/
  {
    path: "/plan",
    element: <Plan />
  },
  {
    path: "/payment/:planId",
    element: <PaymentPage />
  },
  {
    path: "/success",
    element: <PaymentSuccess />
  },
/*============================== temporary comment out for deployment 06/09===================================*/
  {
    path: "/coverletter_history",
    element: <Coverletter_history />
  },
  {
    path: "/unverified",
    element: <UnverifiedEmail />
  },
  {
    path: "/term_of_service",
    element: <Term_of_service />
  },
  {
    path: "/privacy_policy",
    element: <Privacy_policy />
  },
  {
    path: "/new_suggested_edits",
    element: <New_suggested_edits />
  },
  {
    path: "/resubot",
    element: <ResuBot />
  }
   
]);

export const App = () => {
  return <RouterProvider router={router} />;
};

import React, { useRef, useState, useEffect } from 'react';
import { RingScore } from "../../components/Ring/Ring_score";
import { useNavigate } from 'react-router-dom';

const ExperienceDetails = ({ data, job_description, company_analysis, original_data, work_exp_length, 
    original_work_exp_length, mode, executive_summary, selectedExperience, handleExperienceChange, 
    handleExperienceDelete, resetExpCopy, jd_keywords, isFieldEmpty, checkExperienceFields, setIsExperienceFieldsFilled,
    isSkillsCategorized, categorizedSkills }) => {
    const textAreaRef = useRef(null);

    const [experienceDetails, setExperienceDetails] = useState({
        Title: '',
        Duration: '',
        Location: '',
        Company: '',
        Responsibilities: []
    });

    useEffect(() => {
        if (data && data.rendering_data[selectedExperience]) {
            setExperienceDetails({
                Title: data.rendering_data[selectedExperience].Experience.Title,
                Duration: data.rendering_data[selectedExperience].Experience.Duration,
                Location: data.rendering_data[selectedExperience].Experience.Location,
                Company: data.rendering_data[selectedExperience].Experience.Company,
                Responsibilities: data.rendering_data[selectedExperience].Experience.Responsibilities
            });
        }
    }, [selectedExperience, data]);
    


    useEffect(() => {
        setIsExperienceFieldsFilled(checkExperienceFields(experienceDetails));
    }, [experienceDetails, checkExperienceFields, setIsExperienceFieldsFilled]);

    const handleInputChange = (field, value) => {
        const updatedExperienceDetails = { ...experienceDetails, [field]: value };
        setExperienceDetails(updatedExperienceDetails);
        handleExperienceChange(updatedExperienceDetails, selectedExperience);
    };

    const handleDelete = () => {
        handleExperienceDelete(selectedExperience);
    };

    const handleTextareaChange = (e) => {
        const value = e.target.value;
        const responsibilities = value.split('\n').map(line => line.replace(/^•\s*/, ''));
        handleInputChange('Responsibilities', responsibilities);
    };

    const handleTextareaKeyDown = (e) => {
        const { value, selectionStart, selectionEnd } = e.target;

        // Handle Enter key to add bullet point
        if (e.key === 'Enter') {
            const newValue = value.substring(0, selectionStart) + '\n• ' + value.substring(selectionEnd);
            e.target.value = newValue;
            e.target.setSelectionRange(selectionStart + 3, selectionStart + 3);
            handleTextareaChange({ target: { value: newValue } });
            e.preventDefault();
        }

        // Handle Backspace key to remove bullet point
        if (e.key === 'Backspace') {
            const beforeCursor = value.substring(0, selectionStart);
            const afterCursor = value.substring(selectionEnd);

            if (beforeCursor.endsWith('\n• ')) {
                const newValue = value.substring(0, selectionStart - 3) + afterCursor;
                e.target.value = newValue;
                e.target.setSelectionRange(selectionStart - 3, selectionStart - 3);
                handleTextareaChange({ target: { value: newValue } });
                e.preventDefault();
            } else if (selectionStart === 0 && value.startsWith('• ')) {
                const newValue = value.substring(2);
                e.target.value = newValue;
                e.target.setSelectionRange(selectionStart, selectionStart);
                handleTextareaChange({ target: { value: newValue } });
                e.preventDefault();
            }
        }
    };

    const handleCopyToClipboard = (text) => {
        textAreaRef.current.value = text;
        textAreaRef.current.select();
        document.execCommand('copy');
    };

    const navigate = useNavigate();

    const handle_resubot_click = () => {
        navigate('/resubot', { state: { data, 
                                        job_description, 
                                        company_analysis, 
                                        original_data, 
                                        work_exp_length, 
                                        original_work_exp_length,
                                        mode, 
                                        executive_summary, 
                                        selectedExperience, 
                                        resetExpCopy,
                                        jd_keywords,
                                        isSkillsCategorized,
                                        categorizedSkills
                                    } });
    };

    if (!data || !data.rendering_data[selectedExperience]) {
        return (
            <div className="education-container">
                <div className="summary-header">
                    <div className="edu-title">No experience data available, you can restore your experiences by clicking the "Restore All" button</div>
                </div>
            </div>
            );
    }

    return (
        <div className="main-section">
            <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }}></textarea>
            <div className="notice">
                {/* <div className="help-container">
                    <div className="help">
                        <button onClick={handle_resubot_click}>Need help fine-tuning your resume? Chat with ResuBot!</button>
                        <img src="/img/resume_resubot.png" alt="robot Icon" />
                    </div>
                </div> */}
                <div className="experience-header">
                    <h4>{selectedExperience < work_exp_length ?
                    `Work Experience ${selectedExperience + 1}` :
                    `Project Experience ${selectedExperience - work_exp_length + 1}`}</h4>
                    <button onClick={handleDelete} className="delete-button">
                        Delete
                    </button>
                </div>
            </div>

            <div className="score">
                <RingScore score={data.rendering_data[selectedExperience].relevancy_score} />

                <div className="score-text">
                    <h4>Matching Score</h4>
                    <p>Almost perfect! Your resume is highly aligned with the job description. Just a couple of fine-tuning touches and you're good to go!</p>
                </div>
            </div>

            <div className="description">
                <label className="form-label">Role<span className="starColor">*</span></label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Title || ''}
                    onChange={(e) => handleInputChange('Title', e.target.value)} 
                />
                {(!experienceDetails.Title ||isFieldEmpty(experienceDetails.Title)) && (
                    <div className="hint-text">Hint: this content is required.</div>
                )}

                <label className="form-label">Company/Institution<span className="starColor">*</span></label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Company || ''}
                    onChange={(e) => handleInputChange('Company', e.target.value)} 
                />
                {(!experienceDetails.Company ||isFieldEmpty(experienceDetails.Company)) && (
                    <div className="hint-text">Hint: this content is required.</div>
                )}

                <label className="form-label">Duration<span className="starColor">*</span></label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Duration || ''}
                    onChange={(e) => handleInputChange('Duration', e.target.value)} 
                />
                {(!experienceDetails.Duration ||isFieldEmpty(experienceDetails.Duration)) && (
                    <div className="hint-text">Hint: this content is required.</div>
                )}

                <label className="form-label">Location</label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Location || ''}
                    onChange={(e) => handleInputChange('Location', e.target.value)} 
                />
            </div>

            <div className="description">
                <p>Revised Description (Edit Below)</p>
                <div className="help-container">
                    <div className="help">
                        <button onClick={handle_resubot_click}>Need help fine-tuning your resume? Chat with ResuBot!</button>
                        <img src="/img/resume_resubot.png" alt="robot Icon" />
                    </div>
                </div>
                <div className="revised-content">
                    <img
                        className="img"
                        alt="Copy"
                        src="/img/copy.png"
                        onClick={() => handleCopyToClipboard(
                            (data.rendering_data[selectedExperience].Experience.Responsibilities || []).join('\n')
                        )}
                    />
                    <textarea
                        className="experience-textarea"
                        value={(experienceDetails.Responsibilities || []).map(responsibility => `• ${responsibility}`).join('\n')}
                        onChange={handleTextareaChange}
                        onKeyDown={handleTextareaKeyDown}
                        rows={12}
                    />
                </div>


                <p>Original Description</p>

                <div className="current-content">
                    <div className="current-list">
                        <ul>
                            {(data.rendering_data[selectedExperience].original_description || []).map((summary, idx) => (
                                <li key={idx}>{summary}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceDetails;
